import React, {useState} from 'react';
import './RankingProyecto.css';
import Axios from 'axios';
import Constants from '../../Constants';

function RankingProyecto(props) {

    let [selecting, setSelecting] = useState(false);

    const updateRanking = (rank) => {
        if(rank !== props.proyecto.ranking){
            let proy = JSON.parse(JSON.stringify(props.proyecto));
            proy.ranking = rank;
            Axios.put(Constants.api + '/v1/proyecto/'+proy.pkIdProyecto+'?onlyranking=true',proy)
            .then(res => {
                props.refreshData(rank, proy.pkIdProyecto);
            })
        }
        setSelecting(false);
    }

    return (
        <div className="rank-hldr">
            <div className={'rank rank' + props.proyecto.ranking} onClick={(e) => setSelecting(true)}></div>
            {selecting && 
                <div className="rank-options">
                    <div className={'rank rank1'} onClick={(e) => updateRanking(1)}></div>
                    <div className={'rank rank2'} onClick={(e) => updateRanking(2)}></div>
                    <div className={'rank rank3'} onClick={(e) => updateRanking(3)}></div>
                    <div className={'rank rank4'} onClick={(e) => updateRanking(4)}></div>
                    <div className={'rank rank5'} onClick={(e) => updateRanking(5)}></div>
                </div>
            }
        </div>
    );
}

export default RankingProyecto;