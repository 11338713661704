import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import './Step3.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

function Step3(props) {
    const [pasillosData, setPasillosData] = useState({
        pkIdDistribucion: 0,
        nombre: 0,
        nivel: 0,
        largo: '',
        ancho: ''
    });
    const [selectedDist, selectDist] = useState(0);
    const [invalidData, setInvalidData] = useState('');
    const [selectedAdicional, selectAdicional] = useState(0);
    const [distribuciones, setDistribuciones] = useState([]);
    const [distribucionesDrop, setDistribucionesDrop] = useState([]);
    const [adicionales, setAdicionales] = useState([]);
    const [adicionalesDrop, setAdicionalesDrop] = useState([]);
    const [openWheel, setWheel] = useState(false);
    const [distSaved, setDistSaved] = useState([]);
    const [areaTotal, setAreaTotal] = useState(0);
    const [area1, setArea1] = useState(0);
    const [area2, setArea2] = useState(0);
    const [inflacionTipoSuelo, setInflacionTipoSuelo] = useState(0);
    const [inflacionUbicacion, setInflacionUbicacion] = useState(0);
    const [promociones, setPromociones] = useState([]);

    useEffect(() => {
        getDistribuciones();
        axios.get(Constants.api+'/v1/promociones')
            .then((respDP)=>{
                let inicio = new Date(respDP.data[1].fechaInicio.split('T')[0]);
                let fin = new Date(respDP.data[1].fechaFin.split('T')[0]);
                let hoy = new Date();
                console.log(inicio,fin, fin > hoy) 
                setPromociones(respDP.data.filter(promo => new Date(promo.fechaInicio.split('T')[0]) < hoy && new Date(promo.fechaFin.split('T')[0]) > hoy));
            });
    }, []);

    const onSubmit = (goNext = true) => {
        setWheel(true);
        let distPasillos = JSON.parse(JSON.stringify(distribuciones));
        distPasillos.push(pasillosData);
        if (pasillosData.largo>=1 && !distribuciones.some(dist => !dist.nivel || dist.largo<1 || dist.ancho<1) && !adicionales.some(dist => dist.largo<1)){
            setInvalidData('');
            distPasillos.forEach(element => {
                const body = JSON.parse(JSON.stringify(element));
                delete body['categoria'];
                delete body['fkIdCategoria'];
                delete body['pkIdDistribucion'];
                delete body['nombre'];
    
                if (distSaved.some((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)) {
                    axios.put(Constants.api+'/v1/proyecto_distribucion/'+body.pkIdProyectoDistribucion, 
                    body);
                } else {
                    delete body['pkIdProyectoDistribucion'];
                    axios.post(Constants.api+'/v1/proyecto_distribucion', 
                    body);
                }
            });
            adicionales.forEach(element => {
                const body = JSON.parse(JSON.stringify(element));
                delete body['categoria'];
                delete body['fkIdCategoria'];
                delete body['pkIdDistribucion'];
                delete body['nombre'];
    
                if (distSaved.some((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)) {
                    axios.put(Constants.api+'/v1/proyecto_distribucion/'+body.pkIdProyectoDistribucion, 
                    body);
                } else {
                    delete body['pkIdProyectoDistribucion'];
                    axios.post(Constants.api+'/v1/proyecto_distribucion', 
                    body);
                }
            });
            const bodyP = JSON.parse(JSON.stringify(props.miProyecto));
            delete bodyP['comprador'];
            delete bodyP['vendedor'];
            delete bodyP['estimador'];
            if (4 > bodyP.currentStep && goNext) {
                bodyP.currentStep = 4;
            }
            bodyP.inflacionTipoSuelo = inflacionTipoSuelo || 0.0;
            bodyP.inflacionUbicacion = inflacionUbicacion || 0.0;
            
            axios.put(Constants.api+'/v1/proyecto/'+props.miProyecto.pkIdProyecto, 
            bodyP)
            .then((resp)=>{
                setWheel(false);
                if (goNext) {
                    props.nextStep();
                } 
                props.setProyecto(bodyP);
            });
        } else {
            setInvalidData('Debe llenar todos los valores de las distribuciones y pasillos.');
            setWheel(false);
        }
    }
    const checkStyle = (value) => {
		return value ? "error-input" : '';
    }
    const changeItem = (val, key, index) => {
        let proy = JSON.parse(JSON.stringify(distribuciones));
        proy[index][key] = val;
		setDistribuciones(proy);
        getTotalArea(proy,pasillosData);
    }
    const changeItemAdicional = (val, key, index) => {
        let proy = JSON.parse(JSON.stringify(adicionales));
        proy[index][key] = val;
		setAdicionales(proy);
    }
    const changeDrop = (val, type) => {
        if (type === 1) {
            setInflacionUbicacion(val);
        } else {
            setInflacionTipoSuelo(val);
        }
    }
    const changePasillos = (val) => {
        let proy = JSON.parse(JSON.stringify(pasillosData));
        proy.largo = val;
		setPasillosData(proy);
        getTotalArea(distribuciones,proy);
    }

    const setDist = (id) => {
        selectDist(id)
    }
    
    const addDist = () => {
        let newDist = JSON.parse(JSON.stringify(distribuciones));
        newDist.push(distribucionesDrop.filter(dist => dist.pkIdDistribucion == selectedDist)[0]);
        setDistribuciones(newDist);
        setDistribucionesDrop(distribucionesDrop.filter(dist => dist.pkIdDistribucion != selectedDist));
        selectDist(distribucionesDrop.filter(dist => dist.pkIdDistribucion != selectedDist)[0].pkIdDistribucion);
    }

    const addAdiocional = () => {
        let newDist = JSON.parse(JSON.stringify(adicionales));
        newDist.push(adicionalesDrop.filter(dist => dist.pkIdDistribucion == selectedAdicional)[0]);
        setAdicionales(newDist);
        setAdicionalesDrop(adicionalesDrop.filter(dist => dist.pkIdDistribucion != selectedAdicional));
        if (adicionalesDrop.some(dist => dist.pkIdDistribucion != selectedAdicional)) {
            selectAdicional(adicionalesDrop.filter(dist => dist.pkIdDistribucion != selectedAdicional)[0].pkIdDistribucion);
        }
    }

    const deleteItem = (id) => {
        let newDist = JSON.parse(JSON.stringify(distribucionesDrop));
        let distToDelete = JSON.parse(JSON.stringify(distribuciones.filter(dist => dist.pkIdDistribucion == id)[0]));
        distToDelete.nivel = 0;
        distToDelete.largo = '';
        distToDelete.ancho = '';
        newDist.push(JSON.parse(JSON.stringify(distToDelete)));
        newDist = newDist.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        setDistribucionesDrop(newDist);
        selectDist(newDist[0].pkIdDistribucion);
        setDistribuciones(distribuciones.filter(dist => dist.pkIdDistribucion != id));
        delete distToDelete['categoria'];
        delete distToDelete['fkIdCategoria'];
        delete distToDelete['pkIdDistribucion'];
        delete distToDelete['nombre'];
        axios.put(Constants.api+'/v1/proyecto_distribucion/'+distToDelete.pkIdProyectoDistribucion, 
        distToDelete);
        
        setAreaTotal(0);
        getTotalArea(distribuciones.filter(dist => dist.pkIdDistribucion != id),pasillosData);
    }

    const deleteItemAdiocional = (id) => {
        let newDist = JSON.parse(JSON.stringify(adicionalesDrop));
        let distToDelete = JSON.parse(JSON.stringify(adicionales.filter(dist => dist.pkIdDistribucion == id)[0]));
        distToDelete.nivel = 0;
        distToDelete.largo = '';
        distToDelete.ancho = '';
        newDist.push(JSON.parse(JSON.stringify(distToDelete)));
        newDist = newDist.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        setAdicionalesDrop(newDist);
        selectAdicional(newDist[0].pkIdDistribucion);
        setAdicionales(adicionales.filter(dist => dist.pkIdDistribucion != id));
        delete distToDelete['categoria'];
        delete distToDelete['fkIdCategoria'];
        delete distToDelete['pkIdDistribucion'];
        delete distToDelete['nombre'];
        axios.put(Constants.api+'/v1/proyecto_distribucion/'+distToDelete.pkIdProyectoDistribucion, 
        distToDelete);
        
    }

    const getTotalArea = (arr, pasillos) => {
        let area = 0;
        let areaN1 = 0;
        let areaN2 = 0;
        console.log(arr,arr);
        arr.forEach(elem => {
            area += elem.largo*elem.ancho;
            if (elem.nivel == 1) {
                areaN1 += elem.largo*elem.ancho;
            }
            if (elem.nivel == 2) {
                areaN2 += elem.largo*elem.ancho;
            }
        });
        if (area) {
            if (pasillos) {area = area + area*(pasillos.largo/100)};
            setAreaTotal(Math.round(area * 100) / 100);
            setArea1(Math.round(areaN1 * 100) / 100);
            setArea2(Math.round(areaN2 * 100) / 100);
        }
    }

    const getDistribuciones = async () => {
        setWheel(true);
        await axios.get(Constants.api+'/v1/distribucion')
	    .then((resp)=>{	
            resp.data = resp.data.map(dist => {
                dist.fkIdDistribucion = dist.pkIdDistribucion;
                dist.nivel = 0; 
                dist.largo = ''; 
                dist.ancho = ''; 
                return dist;
            });
            resp.data = resp.data.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
            axios.get(Constants.api+'/v1/proyecto_distribucion/'+props.miProyecto.pkIdProyecto)
	        .then((respDP)=>{
                setDistSaved(respDP.data);
                resp.data.forEach(element => {
                    if (respDP.data.some((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)) {
                        element.ancho = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].ancho;
                        element.largo = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].largo;
                        element.nivel = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].nivel;
                        element.pkIdProyectoDistribucion = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].pkIdProyectoDistribucion;
                    }
                    element.fkIdProyecto = props.miProyecto.pkIdProyecto;
                });
                console.log('dist',resp.data)
                setPasillosData(resp.data.filter(dist => dist.nombre.trim() === 'Pasillos')[0]);
                getTotalArea(resp.data,resp.data.filter(dist => dist.nombre.trim() === 'Pasillos')[0]);
                setDistribucionesDrop(resp.data.filter(dist => (!dist.nivel || !dist.largo || !dist.ancho) && (dist.nombre !== 'Pasillos') && (dist.categoria[0].tipo === 1)));
                setAdicionalesDrop(resp.data.filter(dist => (dist.largo<1 && (dist.categoria[0].tipo === 2))));
                if (resp.data.some(dist => (dist.categoria[0].tipo === 2))) {
                    selectAdicional(resp.data.filter(dist => (!dist.nivel || !dist.largo || !dist.ancho) && (dist.nombre !== 'Pasillos') && (dist.categoria[0].tipo === 2))[0].pkIdDistribucion);
                } else if (adicionalesDrop.length) {
                    selectAdicional(adicionalesDrop[adicionalesDrop.length-1].pkIdDistribucion);
                }
                selectDist(resp.data.filter(dist => !dist.nivel || !dist.largo || !dist.ancho)[0].pkIdDistribucion);
                setAdicionales(resp.data.filter(dist => dist.largo>=1 && (dist.categoria[0].tipo === 2)));
                resp.data = resp.data.filter(dist => dist.nivel && dist.largo && dist.ancho && (dist.nombre !== 'Pasillos') && (dist.categoria[0].tipo === 1));
                setDistribuciones(resp.data);
                const inflacionTipoSuelo = props.miProyecto.inflacionTipoSuelo || 0;
                const inflacionUbicacion = props.miProyecto.inflacionUbicacion || 0;
                console.log('inflacionUbicacion 1',props.miProyecto.inflacionUbicacion)
                setInflacionTipoSuelo(inflacionTipoSuelo);
                setInflacionUbicacion(inflacionUbicacion);
                setWheel(false);
            });
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		//this.context.logOut();
	    	}
        });
    }

    return (
        <div className="form-container">
            <div>
                <h3>Distribución:</h3>
                <SaveIcon  className="save-right" onClick={(e) => onSubmit(false)}/>
                <p className="invalid-feedback">{invalidData}</p>
                <div className="distribuciones-table">
                    <div className="distribuciones-header"><span className="nombre">Nombre</span><span className="nivel">Nivel 1</span><span className="nivel">Nivel 2</span><span className="area">Largo</span><span className="area">Ancho</span><span className="area">Area</span></div>
                    {distribuciones.map((dist,index) => {
                        return (
                        <div className="distribuciones-row">
                            <DeleteIcon className="delete-icon" onClick={(e) => deleteItem(dist.pkIdDistribucion)}/><p>{dist.nombre}</p>
                            <RadioGroup aria-label="gender" name={`nivel`} onChange={(e) => changeItem(e.target.value, 'nivel', index)} value={distribuciones[index].nivel.toString()}>
                                <FormControlLabel value="1" control={<Radio />} label="" />
                                <FormControlLabel value="2" control={<Radio />} label="" />
                            </RadioGroup>
                            <div className="form-group">
                                <TextField className={`input-form`} step=".01" name={`largo`} value={distribuciones[index].largo} onChange={(e) => changeItem(e.target.value, 'largo', index)} type="number"/>
                                <TextField className={`input-form`} step=".01" name={`ancho`} value={distribuciones[index].ancho} onChange={(e) => changeItem(e.target.value, 'ancho', index)} type="number"/>
                                {/* {values[`largo-${dist.nombre}`] && values[`ancho-${dist.nombre}`] && <p className="area-text">{values[`largo-${dist.nombre}`] * values[`ancho-${dist.nombre}`]} mts2</p>}
                                {(!values[`largo-${dist.nombre}`] || !values[`ancho-${dist.nombre}`]) && <p className="area-text">0 mts2</p>} */}
                                <p className="area-text">{Math.round(distribuciones[index].largo*distribuciones[index].ancho * 100) / 100} mts2</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
                <div className="form-group drop-dist">
                    <span>Agregar un nuevo espacio</span>
                    <select className={`input-form`} name="fkIdRol" onChange={(e) => setDist(e.target.value)}>
                        {distribucionesDrop.map((dist) => <option key={dist.pkIdDistribucion} value={dist.pkIdDistribucion}>{dist.nombre}</option>)}
                    </select>
                    <AddCircleIcon onClick={addDist} />

                </div>
                <div className="distribuciones-row">
                    <p>Área N1:</p>
                    <div className="form-group">
                        <p className="area-total"><strong>{area1} m2</strong></p>
                    </div>
                </div>
                <div className="distribuciones-row">
                    <p>Área N2:</p>
                    <div className="form-group">
                        <p className="area-total"><strong>{area2} m2</strong></p>
                    </div>
                </div>
                <div className="distribuciones-row">
                    <p>{pasillosData?.nombre}</p>
                    <div className="form-group">
                        <TextField className={`input-form`} name={`pasillosData?.largo`} value={pasillosData?.largo} type="number" onChange={(e) => changePasillos(e.target.value)}/><p>%</p>
                    </div>
                </div>
                <div className="distribuciones-row">
                    <p><strong>Área Total:</strong></p>
                    <div className="form-group">
                        <p className="area-total"><strong>{areaTotal} m2</strong></p>
                    </div>
                </div>           
                <div className="distribuciones-row">
                    <p><strong>Área establecida:</strong></p>
                    <div className="form-group">
                        <p className="area-total"><strong>{props.miProyecto.tamano} m2</strong></p>
                    </div>
                </div>
                <br/>
                {promociones.length > 0 && 
                <div class="promos"> 
                    <h3>Promociones activas:</h3>
                    {promociones.map((promo,index) => {
                        return (
                        <p className="area-text">{promo.descripcion} - {promo.promocion}%</p>
                        );
                    })}
                </div>}
                <br/>
                {adicionales.length>0 && <div>
                    <h3>Adicionales:</h3>
                    <div className="distribuciones-table">
                        <div className="distribuciones-header"><span className="nombre">Nombre</span><span className="area">Cantidad</span></div>
                        {adicionales.map((dist,index) => {
                            return (
                            <div className="distribuciones-row">
                                <DeleteIcon className="delete-icon" onClick={(e) => deleteItemAdiocional(dist.pkIdDistribucion)}/>
                                <p>{dist.nombre}</p>          
                                <TextField className={`input-form`} name={`cantidad`} value={adicionales[index].largo} onChange={(e) => changeItemAdicional(e.target.value, 'largo', index)} type="number"/> 
                                <span>{dist.categoria[0].unidad}</span>                  
                            </div>
                            );
                        })}
                    </div>
                </div>}
                <div className="form-group drop-dist">
                    <span>Agregar un adicional</span>
                    <select className={`input-form`} name="fkIdRol" onChange={(e) => selectAdicional(e.target.value)}>
                        {adicionalesDrop.map((dist) => <option key={dist.pkIdDistribucion} value={dist.pkIdDistribucion}>{dist.nombre}</option>)}
                    </select>
                    <AddCircleIcon onClick={addAdiocional} />
                </div>
                <br/>
                {!area2 ? <div className="form-group">
                    <span>Tipo de Suelo TON/m2</span>
                    <select className={`input-form`} value={inflacionTipoSuelo} name="inflacionTipoSuelo" onChange={(e) => changeDrop(e.target.value, 2)}>
                        <option key="0" value="0">8 TON o +</option>
                        <option key="1" value="2.90">6 TON a 7 TON</option>
                        <option key="2" value="4.90">4 TON a 5 TON</option>
                        <option key="3" value="0">3 TON</option>
                        <option key="4" value="revisar">2 TON o -</option>
                    </select>
                </div> : 
                <div className="form-group">
                    <span>Tipo de Suelo TON/m2</span>
                    <select className={`input-form`} value={inflacionTipoSuelo} name="inflacionTipoSuelo" onChange={(e) => changeDrop(e.target.value, 2)}>
                        <option key="0" value="0">8 TON o +</option>
                        <option key="1" value="1.70">6 TON a 7 TON</option>
                        <option key="2" value="3.50">4 TON a 5 TON</option>
                        <option key="3" value="5.00">3 TON</option>
                        <option key="4" value="revisar">2 TON o -</option>
                    </select>
                </div>}
                <div className="form-group">
                    <span>UBICACIÓN</span>
                    <select className={`input-form`} value={inflacionUbicacion} name="inflacionUbicacion" onChange={(e) => changeDrop(e.target.value, 1)}>
                        <option key="0" value="0">GAM</option>
                        <option key="1" value="5.00">ZONA ALTA MONTAÑA/CARTAGO</option>
                        <option key="2" value="12.00">ZONA NORTE</option>
                        <option key="3" value="20.00">GUANACASTE</option>
                        <option key="4" value="12.01">PUNTARENAS</option>
                        <option key="5" value="20.01">ZONA SUR</option>
                        <option key="6" value="20.02">LIMON</option>
                    </select>
                </div>
                <p className="invalid-feedback">{invalidData}</p>
                <div className="form-group buttons">
                    <button disabled={false} className="btn btn-cancel" onClick={props.prevStep}>
                    Atrás
                    </button>
                    <button type="submit" disabled={false} className="btn btn-save" onClick={onSubmit}>
                    Siguiente
                    </button>
                </div>
            </div>
			{openWheel && <Wheel/>}
        </div>
    );
}

export default Step3;