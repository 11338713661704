import React, { Component, forwardRef } from 'react';
import './Promociones.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../../MainContext';
import Constants from '../../Constants';
import AgregarPromociones from './AgregarPromociones/AgregarPromociones';
import Wheel from '../../shared/dialog/wheel/Wheel';
import Alert from '../../shared/dialog/alert/Alert';

class Promociones extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	columns: [
			  { title: 'Descripción', field: 'descripcion' },			  
              { title: 'Porcentaje', field: 'promocion' },			  
              // { title: 'Fecha Inicio', field: 'fechaInicio' },			  
              // { title: 'Fecha Fin', field: 'fechaFin' }
		    ],
		    data: [],
		    items: [],
	    	showList: true,
	    	selectedItem: {
				base: '',
				precio: ''
            },
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	openEdit = (item)=> {
        this.setState({
            selectedItem: item,
            showList: !this.state.showList
        });
  	}

    showList = ()=> {
    	this.reloadData();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
          this.setState({showList: !this.state.showList});
          this.reloadData();
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedItem: {
                descripcion: ''
            }
  		});
  	}

  	reloadData = () => {
		this.setState({
			openWheel: true
		})
  		axios.get(Constants.api + '/v1/promociones')
	    .then((resp)=>{
		  	this.setState({ 
				openWheel: false,
                data: resp.data/*.map(obj => {
                	const dateArr = obj.fechaInicio.split('T')[0].split('-');
                	const dateArrEnd = obj.fechaFin.split('T')[0].split('-');
                	obj.fechaInicio = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
                	obj.fechaFin = `${dateArrEnd[2]}-${dateArrEnd[1]}-${dateArrEnd[0]}`;
                	return obj;
                })*/
		  	});
	    }).catch(error => {
			this.setState({
				openWheel: false
			})
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
  	}

    componentDidMount(){
        this.reloadData();
    }
    
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	{this.context.hasPermission('crear-rol') && <div className="add-role" onClick={this.openList}>
	            		<h3>Agregar promoción</h3>
	            		<Add />
	            	</div>}	            	
	                <MaterialTable
				      title="Promociones"
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar esta promoción?"
					        },
					        addTooltip: "Agregar nueva promoción",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
                      data={this.state.data}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: Edit,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
                            axios.delete(Constants.api + '/v1/promociones/'+oldData.pkIdPromocion)
                            .then((resp)=>{		  							  	
                                resolve();
                                this.reloadData();
                            }).catch(error => {
                                if (error.message.indexOf('401') >= 0) {
                                    this.context.logOut();
                                }
                                let alertData = this.state.alertData;
                                alertData.message = "No se puede eliminar esta promoción";
                                this.setState({alertData: alertData});
                                resolve();
                                this.toggleAlert();
                            });
				          }),
				      }}
				      actions={[
						  {
						    icon: Edit,
						    tooltip: 'Editar',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
                        ]}
                        options={{
                            paging: true
                        }}
				    />
				</div>}
				{!this.state.showList && <AgregarPromociones togShowList={this.toggleShowList} openList={this.showList} selectedItem={this.state.selectedItem}
				></AgregarPromociones>}
				<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/>
				{this.state.openWheel && <Wheel/>}
			</div>

        );
    }
}

Promociones.contextType = MainContext;
export default Promociones;