import React, {useState, useEffect, useContext} from 'react';
import './Step7.css';
import UsuarioArchivos from '../../../Usuarios/AgregarUsuarios/Archivos/UsuarioArchivos/UsuarioArchivos';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import MainContext from '../../../MainContext';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});



let docsDisenadorToAdd = [];
let docsMarked = [];

function Step7(props) {
    let ctx = useContext(MainContext);
    const [openWheel, setWheel] = useState(false);
    let [newDocsDisenador, setNewDocsDisenador] = useState([]);
    let [docsDisenador, setDocsDisenador] = useState([]);
    let [disenadores, setDisenadores] = useState([]);
    let [disenadorSelected, setDisenadorSelected] = useState(null);
    let [mts, setMts] = useState(0);

    const seleccionarArchivo = (file, type) => {
        if(type === 'disenador'){
            setNewDocsDisenador([...newDocsDisenador, file]);
            docsDisenadorToAdd = [...newDocsDisenador, file];
        }
    }

    const borrarArchivo = file => {
        axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
        .then(succ => {
            loadFiles();
        })
    }

    const loadFiles = () => {
		axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/disenador/`)
        .then(res => {
            setDocsDisenador(res.data.Contents);
        });
    }

    const cargaDisenadores = () => {
        axios.get(Constants.api+`/v1/usuario?permiso=12`)
        .then(res => {
            console.log(res);
            setDisenadores(res.data);
        });
    }

    useEffect(() => {
        loadFiles();
        cargaDisenadores();
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        setDisenadorSelected(proyData.fkIdUsuarioEstimador);
        setMts(proyData.mtsDisenador);
        docsMarked = [];
    },[]);

    const guardaArchivos = async () => {
        if(docsDisenadorToAdd.length > 0) {
			let filesToUpload = [];
            for(let i = 0; i < docsDisenadorToAdd.length; i++){
                const file = docsDisenadorToAdd[i];
                const fileData = await toBase64(file);
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/proyecto/${props.miProyecto.pkIdProyecto}/disenador/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }))
            }
            const res = await Promise.all(filesToUpload);
        }
        setNewDocsDisenador([]);
        docsDisenadorToAdd = [];
        loadFiles();
    }

    const enviarOferta = async () => {
        if(disenadorSelected){
            setWheel(true);
            if(docsDisenadorToAdd.length > 0) await guardaArchivos();
            axios.post(Constants.api+'/v1/alerta', 
            {
                fkIdUsuario: Number(disenadorSelected),
                mensaje:'Proyecto de '+props.miProyecto.comprador[0].nombre+ ' require oferta.',
                revisada: 0,
                link: '/proyectos/'+props.miProyecto.pkIdProyecto
            })
            .then(async (resp)=> {
                console.log('resp, alertas', resp);
            });
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/disenador/`)
                .then(res => {
                    let files = res.data.Contents;
                    let attachments = [];
                    files.forEach(e => {
                        if(docsMarked.some(d => e.Key.indexOf(d) > -1)){
                            attachments.push({
                                type: 'S3',
                                path: e.Key
                            });
                        }
                    });
                    axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdVendedor)
                            .then(vend => {
                                const disenador = disenadores.find(d => d.pkIdUsuario ===  Number(disenadorSelected));
                                let email = {
                                    emailFiles: attachments,
                                    emailBody:{
                                        from: vend.data[0].correos[0].correo,
                                        to: [disenador.correo],
                                        subject: 'Solicitud de presupuesto para '+ props.miProyecto.comprador[0].nombre,
                                        body: 'Solicitud de presupuesto para '+ props.miProyecto.comprador[0].nombre,
                                        type: 'template',
                                        name: 'listo-para-presupuestista',
                                        mappings: {
                                            nombrepresupuestista: `${disenador.nombre} ${disenador.apellido1} ${disenador.apellido2}`,
                                            nombrevendedor: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                            nombreproyecto: 'Proyecto número ' + proyData.pkIdProyecto,
                                            linkproyecto: `${Constants.app}/proyectos/${proyData.pkIdProyecto}`,
                                            imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                                        }
                                    }
                                }
                                axios.post(Constants.api+'/v1/send_mail/', email)
                                .then(res => {console.log('email sent')});
                            });
                    delete proyData['comprador'];
                    delete proyData['vendedor'];
                    delete proyData['estimador'];
                    proyData.currentStep = 8;
                    proyData.fkIdUsuarioEstimador = Number(disenadorSelected);
                    axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
                        proyData)
                    .then(async (resp)=> {
                        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
                        proyData.fkIdUsuarioEstimador = Number(disenadorSelected);
                        proyData.currentStep = 8;
                        props.setProyecto(proyData);
                        setWheel(false);
                        props.nextStep();
                    });
                });
        } else {
            alert('Por favor seleccione un presupuestista para el proyecto');
        }
    }

    const enviarVendedor = async () => {
        setWheel(true);
        if(docsDisenadorToAdd.length > 0) await guardaArchivos();
        axios.post(Constants.api+'/v1/alerta', 
        {
            fkIdUsuario: Number(props.miProyecto.fkIdVendedor),
            mensaje:'Proyecto de '+props.miProyecto.comprador[0].nombre+ ' ya tiene diseño.',
            revisada: 0,
            link: '/proyectos/'+props.miProyecto.pkIdProyecto
        })
        .then(async (resp)=> {
            console.log('resp, alertas', resp);
        });
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/disenador/`)
            .then(res => {
                let files = res.data.Contents;
                let attachments = [];
                files.forEach(e => {
                    if(docsMarked.some(d => e.Key.indexOf(d) > -1)){
                        attachments.push({
                            type: 'S3',
                            path: e.Key
                        });
                    }
                });
                axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdVendedor)
                            .then(vend => {
                                const disenador = disenadores.find(d => d.pkIdUsuario ===  Number(proyData.fkIdDisenador));
                                let email = {
                                    emailFiles: attachments,
                                    emailBody:{
                                        from: disenador.correo,
                                        to: [vend.data[0].correos[0].correo],
                                        subject: 'Diseño del proyecto '+ props.miProyecto.comprador[0].nombre +' listo',
                                        body: 'Diseño del proyecto '+ props.miProyecto.comprador[0].nombre +' listo',
                                        type: 'template',
                                        name: 'diseno-listo',
                                        mappings: {
                                            nombredisenador: `${disenador.nombre} ${disenador.apellido1} ${disenador.apellido2}`,
                                            nombrevendedor: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                            nombreproyecto: 'Proyecto número ' + proyData.pkIdProyecto,
                                            linkproyecto: `${Constants.app}/proyectos/${proyData.pkIdProyecto}`,
                                            imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdDisenador}/firma" alt="firma" width="433"/>`
                                        }
                                    }
                                }
                                axios.post(Constants.api+'/v1/send_mail/', email)
                                .then(res => {
                                    setWheel(false);
                                    alert('Documentos enviados');
                                    console.log('email sent');
                                });
                            });
                delete proyData['comprador'];
                delete proyData['vendedor'];
                delete proyData['estimador'];
                proyData.mtsDisenador = mts;
                axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
                    proyData)
                .then(async (resp)=> {
                    let proyData = JSON.parse(JSON.stringify(props.miProyecto));
                    proyData.mtsDisenador = mts;
                    props.setProyecto(proyData);
                    setWheel(false);
                    // props.nextStep();
                });
            });
    }

    const changeDisenador = (u) => {
        setDisenadorSelected(u);
    }

    const handleChange = (c) => {
        setMts(c.target.value);
    }

    const handleChangeCheck = (e,ad) => {
        // ad.send = e.target.checked;
        console.log(ad);
        if(e.target.checked){
            docsMarked.push(ad);
        } else {
            docsMarked = docsMarked.filter(d => d != ad);
        }
        console.log(docsMarked);
    }

    return (
        <div>
            <div className="form-container">
                <div className="cont">
                    <div>
                        Notas del vendedor:<br></br>
                        {props.miProyecto.notas}
                    </div>
                    <br></br>
                    <div>
                        <TextField className="input-form" name="mts" onChange={handleChange} value={mts} label="Mts del diseño"/>
                        <br></br>
                        Documentos de diseñador
                        <UsuarioArchivos type="" seleccionarArchivo={(e) => seleccionarArchivo(e, 'disenador')}> </UsuarioArchivos>
                        {newDocsDisenador.length > 0 && <div>
                            Archivos a agregar:
                            <ul>
                                {newDocsDisenador.map(ad => {
                                    return <li><input type="checkbox" onChange={e => handleChangeCheck(e, ad.name)}/><span key={ad.name}>{ad.name}</span></li>
                                })}
                            </ul>
                        </div>}
                        {docsDisenador.length > 0 && <div>
                            Documentos de diseñador y del vendedor asignados al proyecto:
                            <ul>
                                {docsDisenador.map(ad => {
                                    return (
                                        <li>
                                            <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank"><input type="checkbox" onChange={e => handleChangeCheck(e, ad.Key.split('/').pop())}/>{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>}
                    </div>
                    {props.miProyecto.fkIdVendedor == JSON.parse(localStorage.getItem('izqToken')).usuarioId && <div className="form-group">
                        <span>Presupuestistas</span>
                        <select value={disenadorSelected} name="disenadorSelected" onChange={(e) => changeDisenador(e.target.value)}>
                            <option key="0" value="">Seleccione el Presupuestistas</option>
                            {disenadores.map((d) => <option key={d.fkIdUsuario} value={d.fkIdUsuario}>{d.nombre} {d.apellido1}</option>)}
                        </select>
                    </div>}
                </div>
            </div>
            <div className="form-container">
                <div>
                    <button className="btn btn-cancel" onClick={props.prevStep}>
                        Atrás
                    </button>
                    {ctx.hasPermission('disenar-proyecto') && <button type="submit" className="btn btn-save" onClick={enviarVendedor}>
                        Enviar diseños a vendedor
                    </button>}
                    {ctx.hasPermission('crear-proyecto') && props.miProyecto.fkIdVendedor == JSON.parse(localStorage.getItem('izqToken')).usuarioId && docsDisenador.length > 0 && <button type="submit" className="btn btn-save" onClick={enviarOferta}>
                        Enviar a presupuestista
                    </button>}
                    {ctx.hasPermission('disenar-proyecto') && docsDisenador.length == 0 && <span>
                        No se puede enviar a vendedor hasta que existan documentos del diseñador
                    </span>}
                </div>
            </div>
            {openWheel && <Wheel/>}
        </div>
    );
}

export default Step7;