import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Alert extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	open: false
	    }
	}

    render() {
        return (
            <div>
                <Dialog
			        open={this.props.isOpen}
			        onClose={this.props.onClose}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
			        <DialogTitle id="alert-dialog-title">{this.props.data.title}</DialogTitle>
			        <DialogContent>
			          <DialogContentText id="alert-dialog-description">
			            {this.props.data.message}
			          </DialogContentText>
			        </DialogContent>
			        <DialogActions>
			          {this.props.data.cancel && <Button onClick={this.props.onClose} color="primary">
			            {this.props.data.cancel}
			          </Button>}
			          <Button onClick={this.props.onAccept} color="primary" autoFocus>
			            {this.props.data.accept}
			          </Button>
			        </DialogActions>
			    </Dialog>
            </div>
        );
    }
}

export default Alert;