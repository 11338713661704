import React from 'react';
import Axios from 'axios';
import MainContext from '../MainContext';
import Constants from '../Constants';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
let interval;

function Alertas(props) {
    const mainContext = React.useContext(MainContext);

    let [alerts, setAlerts] = React.useState([]);
    let [isAlertsOpen, setIsAlertsOpen] = React.useState(false);

    const getPendAlerts = () => {
        const pendAlerts = alerts.filter(a => a.revisada === 0);
        return pendAlerts.length;
    }

    const toogleShowAlerts = () => {
        setIsAlertsOpen(!isAlertsOpen);
    }

    const getAlerts = () => {
        Axios.get(Constants.api + '/v1/alerta?usuario=' + mainContext.myUser.pkIdUsuario)
        .then(res => {
            const alerts = res.data.filter(a => a.revisada !== 1).reverse()
            setAlerts(alerts);
        })
    }

    const handleClickAlert = (a) => {
        toogleShowAlerts();
        // a.revisada = 1;
        // Axios.put(Constants.api + '/v1/alerta/' + a.pkIdAlerta, a)
        // .then(res => {
        //     getAlerts();
        // })
    }

    React.useEffect(()=>{
        if (mainContext.myUser.pkIdUsuario) {
            if(!interval){
                getAlerts();
                interval = setInterval(()=>{
                    getAlerts();
                }, 30000);
            }
        }
    }, [...Object.values(mainContext)])

    return (
        <div>
            <IconButton color="inherit" onClick={toogleShowAlerts}>
              <Badge badgeContent={alerts.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {isAlertsOpen && <div className="alerts">
              <ul>
                {alerts.map(a => {
                  return (<li key={a.pkIdAlerta} className={a.revisada === 1 ? 'alerta revisada' : 'alerta'}>
                      {a.link === null && a.mensaje} 
                      {a.link && <Link onClick={() => handleClickAlert(a)} to={a.link+`?alerta=${a.pkIdAlerta}`}>{a.mensaje}</Link>}
                    </li>)
                })}
              </ul>
            </div>}
        </div>
    );
}

export default Alertas;