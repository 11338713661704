import React, { Component, forwardRef } from 'react';
import './Proyectos.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../MainContext';
import Constants from '../Constants';
import AgregarProyecto from './AgregarProyecto/AgregarProyecto';
import Alert from '../shared/dialog/alert/Alert';
import Wheel from '../shared/dialog/wheel/Wheel';
import RankingProyecto from './RankingProyecto/RankingProyecto';
import { withRouter } from "react-router-dom";

class Proyectos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openWheel: false,
	      	columns: [],
		    userData: [],
		    roles: [],
	    	showList: true,
	    	selectedUser: {},
			alertData: {
				title: "Error",
				message: "No puede eliminar su propio proyecto",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			myId:  JSON.parse(localStorage.getItem('izqToken')).proyectoId
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}


	openEdit = (user)=> {
		this.setState({selectedUser: user});
        this.props.history.push("proyectos/"+user.pkIdProyecto);
	}
	  
	refreshProyRanking = (ranking, id) => {
		let data = JSON.parse(JSON.stringify(this.state.userData));
		let proy = data.find(p => p.pkIdProyecto === id);
		proy.ranking = ranking;
		this.setState({
			userData: data
		})
	}

    showList = ()=> {
    	this.loadProyectos();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
  		this.setState({showList: !this.state.showList});
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedUser: {}
  		});
  	}

	getSimbol = (val) => {
		return val==1 ? '₡' : '$';
	}

	numberWithCommas = (x) => {
		x = parseInt(x.toString().replace(/,/g, ''));
		return x? x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):'';
    }

  	loadProyectos = () => {
		this.setState({openWheel:true});
  		axios.get(Constants.api + '/v1/proyecto')
	    .then((resp)=>{	
            resp.data = resp.data.map(proy => {

				// console.log('proy.comprador',proy);
				// proy.comprador = proy.comprador || {nombre: '', apellido1: '', telefono: ''};
				proy.nombreComprador = proy.nombre + ' ' + proy.apellido1; 
				if(proy.vendedor)
					proy.nombreVendedor = proy.vendedor.nombre + ' ' + proy.vendedor.apellido1; 
				if(proy.fechaIngreso)
					proy.fechaIngresoMapped = proy.fechaIngreso.split('T')[0];
				if(proy.monedaPresupuesto && proy.presupuesto)
					proy.presupuestoMapped = this.getSimbol(proy.monedaPresupuesto)+this.numberWithCommas(proy.presupuesto);
                return proy;
			})
			console.log(resp.data);
		  	this.setState({
		  		userData: resp.data
			  });
			this.setState({openWheel:false});
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
	  }
	  
	componentDidUpdate(prevProps, prevState){
		if (!prevState.showList) {
			this.setState({showList: true});
			this.loadProyectos();
		}
	}
	
	componentDidMount(){
		this.loadProyectos();
		let columns = [
			{ title: 'Comprador', field: 'nombreComprador' },
			{ title: 'Teléfono', 
				field: 'telefono',
			  render: rowData => <a href={"tel:"+rowData.telefono}>{rowData.telefono}</a> },
			{ title: 'FechaIngreso', field: 'fechaIngresoMapped' },
			{ title: 'Presupuesto', field: 'presupuestoMapped'},
			{ title: 'Ranking', 
				field: 'ranking', 
				render: rowData => <RankingProyecto proyecto={rowData} refreshData={this.refreshProyRanking}/> }
		];
		console.log(this.context);
		const loadColumns = () => {
			if(this.context.hasPermission('crear-usuario')){
				columns.splice(4, 0, { title: 'Vendedor', field: 'nombreVendedor' });
			}
			this.setState({
				columns: columns
			})
		}
		if(this.context.myUser.permisos.length > 0){
			loadColumns();
		} else {
			setTimeout(()=> {
				console.log('will load in here');
				loadColumns();
			}, 1000);
		}
    }
    
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	{this.context.hasPermission('crear-proyecto') && <div className="add-user" onClick={this.openList}>
	            		<h3>Agregar Proyecto</h3>
	            		<Add />
	            	</div>}
	                <MaterialTable
				      title="Proyectos"
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar este Proyecto?"
					        },
					        addTooltip: "Agregar nuevo Proyecto",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
				      data={this.state.userData}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: VisibilityIcon,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
							if (oldData.pkIdProyecto !== this.state.myId && this.context.hasPermission('borrar-proyecto')) {
					           	axios.delete(Constants.api + '/v1/proyecto/'+oldData.pkIdProyecto)
							    .then((resp)=>{		  							  	
					              resolve();
					              this.loadProyectos();
							    }).catch(error => {
							    	if (error.message.indexOf('401') >= 0) {
							    		this.context.logOut();
							    	} else {
							    		let alertData = this.state.alertData;
								    	alertData.message = "No se puede eliminar un proyecto con datos asociados";
								    	this.setState({alertData: alertData});					  	
						              	resolve();
						              	this.toggleAlert();
							    	}
						        });
					    	} else {
					    		let alertData = this.state.alertData;
						    	alertData.message = "No tienes permiso para eliminar este proyecto";
						    	this.setState({alertData: alertData});					  	
						        resolve();	
				              	this.toggleAlert();
					    	}
				          }),
				      }}
				      actions={[
						  {
						    icon: VisibilityIcon,
						    tooltip: 'Ver',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
						]}
				    />
				</div>}
				{!this.state.showList && <AgregarProyecto togShowList={this.toggleShowList} openList={this.showList} selectedUser={this.state.selectedUser} rolList={this.state.roles}
				></AgregarProyecto>}
				<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/>
				{this.state.openWheel && <Wheel/>}
			</div>

        );
    }
}

Proyectos.contextType = MainContext;
export default withRouter(Proyectos);