import React from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems, secondaryListItems } from './listItems';
import Home from './Home/Home';
import Clientes from './Clientes/Clientes';
import Tareas from './Tareas/Tareas';
import Roles from './Roles/Roles';
import Admin from './Admin/Admin';
import CategoriaAcabado from './Admin/CategoriaAcabado/CategoriaAcabado';
import CategoriaDistribucion from './Admin/CategoriaDistribucion/CategoriaDistribucion';
import Acabado from './Admin/Acabado/Acabado';
import PreciosBase from './Admin/PreciosBase/PreciosBase';
import Promociones from './Admin/Promociones/Promociones';
import Distribucion from './Admin/Distribucion/Distribucion';
import Usuarios from './Usuarios/Usuarios';
import AgregarUsuarios from './Usuarios/AgregarUsuarios/AgregarUsuarios';
import Reportes from './Reportes/Reportes';
import Proyectos from './Proyectos/Proyectos';
import AgregarProyecto from './Proyectos/AgregarProyecto/AgregarProyecto';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from './assets/img/LOGO_ORILLAS_BLANCAS.png';
import {
  NavLink,
  Link
} from "react-router-dom";
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import MainContext from './MainContext';
import Constants from './Constants';
import Alertas from './Alertas/Alertas';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


export default function Dashboard() {
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#cc0000'
      }
    },
  });
  const classes = useStyles();
  const checkWidth = () => {
    if (window.innerWidth <= 900) {
      return false;
    } else {
      return true;
    }
  };
  let [open, setOpen] = React.useState(checkWidth());
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getUserName = () => {
    return localStorage.getItem('userName');
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  let location = useLocation();
  console.log(window.innerWidth);

  const mainContext = React.useContext(MainContext);

  return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              
            </Typography>
            <NavLink to="/mi-usuario" exact activeClassName="active">
              <ListItem button className="user-section">
                {mainContext.myUser.imgUrl && <img src={Constants.imgHost + mainContext.myUser.imgUrl} width="40"></img>}
                {!mainContext.myUser.imgUrl && <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>}
                <ListItemText primary={getUserName()} />
              </ListItem>
            </NavLink>
            {/* <IconButton color="inherit" onClick={toogleShowAlerts}>
              <Badge badgeContent={getPendAlerts()} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {isAlertsOpen && <div className="alerts">
              <ul>
                {alerts.map(a => {
                  return (<li key={a.pkIdAlerta} className={a.revisada === 1 ? 'alerta revisada' : 'alerta'}>
                      {a.link === null && a.mensaje} 
                      {a.link && <Link onClick={() => handleClickAlert(a)} to={a.link}>{a.mensaje}</Link>}
                    </li>)
                })}
              </ul>
            </div>} */}
            <Alertas />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={mainContext.logOut}
              className="logout-button"
            >
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            Constructora Izquierdo
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
          <img className="logo-img" src={logo} />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route path="/clientes">
                <Clientes />
              </Route>
              <Route path="/admin/roles">
                <Roles />
              </Route>
              <Route path="/usuarios">
                <Usuarios />
              </Route>
              <Route path="/mi-usuario">
                <AgregarUsuarios />
              </Route>
              <Route path="/reportes">
                <Reportes />
              </Route>
              <Route path="/admin" exact>
                <Admin />
              </Route>
              <Route path="/admin/categoria_acabado" exact>
                <CategoriaAcabado />
              </Route>
              <Route path="/admin/categoria_distribucion" exact>
                <CategoriaDistribucion />
              </Route>
              <Route path="/admin/precios_base" exact>
                <PreciosBase />
              </Route>
              <Route path="/admin/promociones" exact>
                <Promociones />
              </Route>
              <Route path="/admin/acabado" exact>
                <Acabado />
              </Route>
              <Route path="/admin/distribucion" exact>
                <Distribucion />
              </Route>
              <Route path="/proyectos" exact>
                <Proyectos />
              </Route>
              <Route path="/tareas" exact>
                <Tareas />
              </Route>
              <Route path="/proyectos/:id" exact>
                <AgregarProyecto />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Container>
          <div className="fade-bg"></div>
        </main>
    </div>
  );
}
