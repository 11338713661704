import React, { Component } from 'react';
import './AgregarCategoriaAcabado.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Wheel from '../../../shared/dialog/wheel/Wheel';

class AgregarCategoriaAcabado extends Component {

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        categoria: {
				nombre: '',
				ranking: '',
				fkIdCategoriaDistribucion: ''
			},
			categoriaDist: [],
			alertData: {
				title: "Error",
				message: "No Se puede editar esta categoría",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
		let catArray = this.props.categoriasList.filter((elem)=>elem.ranking >= values.ranking && elem.pkIdCategoriaAcabado !== values.pkIdCategoriaAcabado);
		let newRanking = values.ranking;
		for (let i = 0; i < catArray.length; i++) {
				delete catArray[i]['tableData'];
				newRanking++;
				catArray[i].ranking = newRanking;
				axios.put(Constants.api+'/v1/categoria_acabado/' + catArray[i].pkIdCategoriaAcabado,catArray[i]);
		}
        delete values['tableData'];
		if(this.state.categoria.pkIdCategoriaAcabado) {
			axios.put(Constants.api+'/v1/categoria_acabado/' + values.pkIdCategoriaAcabado,values)
			.then(async resp => {
				this.setState({
					openWheel: false
				});
                this.props.openList();
			});
		// }  if(this.state.categoria.pkIdCategoriaAcabado) {
		// 	this.setState({
		// 		openWheel: false
		// 	});
		// 	this.toogleAlert();
		} else {
			axios.post(Constants.api+'/v1/categoria_acabado',values)
			.then(async (resp) => {
				this.setState({
					openWheel: false,
					categoria: {
						nombre: values.nombre,
						fkIdCategoriaDistribucion: values.fkIdCategoriaDistribucion,
						pkIdCategoriaAcabado: resp.data[0].insertId
					}
				});
                this.props.openList();
			});
		}
	}

	componentDidMount() {
		axios.get(Constants.api + '/v1/categoria_distribucion')
		.then((resp)=>{
			this.setState({ 
			});
			if (this.props.selectedItem.ranking) {
				this.setState({
					categoria: this.props.selectedItem,
					categoriaDist: resp.data
				});
			} else {
				this.setState({
					categoriaDist: resp.data,
					categoria: {
						nombre: '',
						fkIdCategoriaDistribucion: '',
						ranking: this.props.categoriasList.length+1
					}
				});
			}
		}).catch(error => {
			this.setState({
				openWheel: false
			});
			if (error.message.indexOf('401') >= 0) {
				this.context.logOut();
			}
		});
		
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.categoria}
							validationSchema={Yup.object().shape({
						        nombre: Yup.string()
									.required('Ingrese el nombre'),
								ranking: Yup.string()
									.required('Ingrese el Ranking'),
								fkIdCategoriaDistribucion: Yup.string().required('seleccione una opción')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								{this.state.categoria.nombre && <h3>Editar categoría de acabado:</h3>}
								{!this.state.categoria.nombre && <h3>Nueva categoría de acabado:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre"/>
                                	<p className="invalid-feedback">{errors.nombre}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.ranking)}`} name="ranking" onChange={handleChange} value={values.ranking} label="Ranking"/>
									<p className="invalid-feedback">{errors.ranking}</p>
								</div>
								<div className="form-group">
									<select className={`input-form ${this.checkStyle(errors.fkIdCategoriaDistribucion)}`} value={values.fkIdCategoriaDistribucion} name="fkIdCategoriaDistribucion" onChange={handleChange} value={values.fkIdCategoriaDistribucion}>
										<option key="0" value="">Seleccione la categoria de distribución</option>
										{this.state.categoriaDist.map((cat) => <option key={cat.pkIdCategoria} value={cat.pkIdCategoria}>{cat.nombre}</option>)}
									</select>
									<p className="invalid-feedback">{errors.fkIdCategoriaDistribucion}</p>
								</div>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarCategoriaAcabado.contextType = MainContext;
export default AgregarCategoriaAcabado;