import React, { Component, forwardRef } from 'react';
import './Usuarios.css';
import axios from 'axios';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Add from '@material-ui/icons/AddCircle';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainContext from '../MainContext';
import Constants from '../Constants';
import AgregarUsuarios from './AgregarUsuarios/AgregarUsuarios';
import Alert from '../shared/dialog/alert/Alert';
import Wheel from '../shared/dialog/wheel/Wheel';

class Usuarios extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
			openWheel: false,
	      	columns: [
		      { title: 'Nombre', field: 'nombre' },
		      { title: 'Apellido', field: 'apellido1' },
		      { title: 'Usuario', field: 'usuario' },
		      { title: 'Correo', field: 'correo' },
		      { title: 'Rol', field: 'rolName'}
		    ],
		    userData: [],
		    roles: [],
	    	showList: true,
	    	selectedUser: {},
			alertData: {
				title: "Error",
				message: "No puede eliminar su propio usuario",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			myId:  JSON.parse(localStorage.getItem('izqToken')).usuarioId
	    }
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}


	openEdit = (user)=> {
  		this.setState({
  			selectedUser: user,
  			showList: !this.state.showList
  		});
  	}

    showList = ()=> {
    	this.reloadUsers();
  		this.setState({showList: true});
  	}

    toggleShowList = ()=> {
  		this.setState({showList: !this.state.showList});
  	}
  	openList = ()=> {
  		this.setState({
  			showList: false,
  			selectedUser: null
  		});
  	}

  	reloadUsers = () => {
		this.setState({openWheel:true});
  		axios.get(Constants.api + '/v1/usuario?type=usuarios')
	    .then((resp)=>{	
	    	console.log(resp.data, this.state.roles );	  
		  	let newUsers = resp.data.map((user) => {
		  		user.rolName = this.state.roles.find(rol => rol.pkIdRol === user.fkIdRol).nombre;
		  		return user;
		  	});
		  	this.setState({
		  		userData: newUsers
		  	});
	    	console.log(newUsers,this.state.userData);
			this.setState({openWheel:false});
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
  	}

	componentDidUpdate(prevProps, prevState){
		if (!prevState.showList) {
			this.setState({showList: true});
			//this.reloadUsers();
		}
	}
	componentDidMount(){
		this.setState({openWheel:true});
	 	axios.get(Constants.api + '/v1/rol?all=true')
		    .then((resp)=>{		    	
			  	this.setState({
			  		roles: resp.data
			  	});
			  	this.reloadUsers();
		    }).catch(error => {
		    	if (error.message.indexOf('401') >= 0) {
		    		this.context.logOut();
		    	}
            });

	}
    render() {
        return (
        	<div>        	
	            {this.state.showList && <div className="table-container">
	            	<div className="add-user" onClick={this.openList}>
	            		<h3>Agregar Usuario</h3>
	            		<Add />
	            	</div>
	                <MaterialTable
				      title="Usuarios"
				      localization={{
				        header: {
				            actions: 'Acciones'
				        },
				        body: {
				            emptyDataSourceMessage: 'No hay datos',				            
					        editRow: {
					          saveTooltip: "Aceptar",
					          cancelTooltip: "Cancelar",
					          deleteText: "Realmente desea eliminar este Usuario?"
					        },
					        addTooltip: "Agregar nuevo Usuario",
					        deleteTooltip: "Eliminar",
					        editTooltip: "Editar"
					      
				        },
				        toolbar: { searchPlaceholder: 'Buscar' }
				      }}
				      columns={this.state.columns}
				      data={this.state.userData}
				      icons={{ 
			            Check: Check,
			            DetailPanel: ChevronRight,
			            Export: SaveAlt,
			            Filter: FilterList,
			            FirstPage: FirstPage,
			            LastPage: LastPage,
			            NextPage: ChevronRight,
			            PreviousPage: ChevronLeft,
			            Search: Search,
			            Delete: forwardRef((props, ref) => <Remove {...props} ref={ref} className="red-icon"/>),
			            Edit: Edit,
			            Add: Add,
			            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="red-icon"/>),
			            ResetSearch: Clear,
			            SortArrow: ArrowDownward
			          }}
				      editable={{
				        onRowDelete: (oldData) =>
				          new Promise((resolve) => {
							if (oldData.pkIdUsuario !== this.state.myId) {
					           	axios.delete(Constants.api + '/v1/usuario/'+oldData.pkIdUsuario)
							    .then((resp)=>{		  							  	
					              resolve();
					              this.reloadUsers();
							    }).catch(error => {
							    	if (error.message.indexOf('401') >= 0) {
							    		this.context.logOut();
							    	} else {
							    		let alertData = this.state.alertData;
								    	alertData.message = "No se puede eliminar un usuario con datos asociados";
								    	this.setState({alertData: alertData});					  	
						              	resolve();
						              	this.toggleAlert();
							    	}
						        });
					    	} else {
					    		let alertData = this.state.alertData;
						    	alertData.message = "No puede eliminar su propio usuario";
						    	this.setState({alertData: alertData});					  	
						        resolve();	
				              	this.toggleAlert();
					    	}
				          }),
				      }}
				      actions={[
						  {
						    icon: Edit,
						    tooltip: 'Editar',
						    onClick: (event, rowData) => this.openEdit(rowData)
						  }
						]}
				    />
				</div>}
				{!this.state.showList && <AgregarUsuarios togShowList={this.toggleShowList} openList={this.showList} selectedUser={this.state.selectedUser} rolList={this.state.roles}
				></AgregarUsuarios>}
				<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toggleAlert} onAccept={this.toggleAlert}/>
				{this.state.openWheel && <Wheel/>}
			</div>

        );
    }
}

Usuarios.contextType = MainContext;
export default Usuarios;