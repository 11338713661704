import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard.js';
import Login from './Login/Login.js';
import MainContext from './MainContext';
import axios from 'axios';
import Constants from './Constants';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		  	isLogged : false,		  	
	    	token: JSON.parse(localStorage.getItem('izqToken')) ? JSON.parse(localStorage.getItem('izqToken')).token : '',
	    	myUser: {permisos:[]},
	    	hasPermission: (val)=> {
		  		return this.state.myUser.permisos.some(elem => elem.nombre === val);
		  	},
		  	toggleIsLogged: ()=> {
		  		this.setState({isLogged : !this.state.isLogged});
		  	},
		  	logOut: () => {
				localStorage.removeItem('izqToken');
				axios.defaults.headers.common['Authorization'] = null;
				this.setState({isLogged : false});
			},
			logIn: (val) => {
				localStorage.setItem('izqToken', val);
				
				axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('izqToken')).token;
				this.setState({
					isLogged : true,
					token: JSON.parse(localStorage.getItem('izqToken')).token
				});
				axios.get(`${Constants.api}/v1/usuario/${JSON.parse(localStorage.getItem('izqToken')).usuarioId}`)
			    .then((resp)=>{	
				  	this.setState({
				  		myUser: resp.data[0]
				  	});
					console.log('permisos',this.state.myUser.permisos)
			    })
			}
		}
	}

	checkLogged = () => {
		this.setState({isLogged : localStorage.getItem('izqToken')});
		if (JSON.parse(localStorage.getItem('izqToken'))) {
			axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('izqToken')).token;
		}
		if(localStorage.getItem('izqToken'))
			this.getUserData();
	}

	getUserData = () => {
		axios.get(`${Constants.api}/v1/usuario/${JSON.parse(localStorage.getItem('izqToken')).usuarioId}`)
	    .then((resp)=>{	
		  	this.setState({
		  		myUser: resp.data[0]
		  	});
	    })
	}

  	componentDidMount() {
		this.checkLogged();
		if (JSON.parse(localStorage.getItem('izqToken'))) {
			this.setState({token: JSON.parse(localStorage.getItem('izqToken')).token});
			this.getUserData();
			// setInterval(() => {
			// 	if (this.state.isLogged) {
			// 		this.getUserData();
			// 	}
			// }, 300000);
		}
  	}

	render() {
		return (
			<MainContext.Provider value={this.state}>
				{ !this.state.isLogged && <Login></Login>}
				{ this.state.isLogged && <Dashboard></Dashboard>}
			</MainContext.Provider>
		);
	}
}

export default App;
