import axios from 'axios';
import Constants from '../../../Constants';

export default class Calc {
    
    constructor () {}

    getPrecioTotal = async (acabados, distribuciones, proyecto, mtsPasillos) => {
        console.log('inicia calculo:' + new Date());
        //console.log(proyecto, mtsPasillos);
        console.log('distribuciones', distribuciones);
        //console.log(proyecto, mtsPasillos);
        let arrTotales = [];
        let total = 0;

        const preciosBase = await axios.get(Constants.api+'/v1/precio_base');
       //distribuciones.forEach(dist => {

        for (let i = 0; i <acabados.length; i ++) {
            const acab = acabados[i];
            if (acab.categoria.toLowerCase().indexOf('closset pared cuarto principal') > -1) {        
                const exist = arrTotales.find(elem => elem.nombre === 'closset principal');
                if (exist) {
                    exist.sumaPrecio += parseFloat(acab.versiones[acab.versiones.length-1].precio);
                } else {
                    arrTotales.push({
                        id: null,
                        nombre: 'closset principal',
                        mts: 0,
                        sumaPrecio: parseFloat(acab.versiones[acab.versiones.length-1].precio)
                    });
                }
            } else if (acab.categoria.toLowerCase().indexOf('closset secundarios') > -1) {          
                const exist = arrTotales.find(elem => elem.nombre === 'closset secundarios');
                if (exist) {
                    exist.sumaPrecio += parseFloat(acab.versiones[acab.versiones.length-1].precio);
                } else {
                    arrTotales.push({
                        id: null,
                        nombre: 'closset secundarios',
                        mts: 0,
                        sumaPrecio: parseFloat(acab.versiones[acab.versiones.length-1].precio)
                    });
                }
            } else {
                const dist = distribuciones.find(elem => elem.fkIdCategoria === acab.fkIdCategoriaDistribucion);            
                const exist = dist ? arrTotales.find(elem => elem.id === dist.fkIdCategoria) : false;
                console.log(acab.fkIdCategoriaDistribucion, dist, exist)
                if (exist) {
                    exist.sumaPrecio += parseFloat(acab.versiones[acab.versiones.length-1].precio);
                } else if (dist) {
                    arrTotales.push({
                        id: dist.fkIdCategoria,
                        nombre: dist.categoria[dist.categoria.length-1].nombre,
                        mts: 0,
                        sumaPrecio: parseFloat(acab.versiones[acab.versiones.length-1].precio)
                    });
                } else if (acab.versiones[acab.versiones.length-1].precio){ 
                    arrTotales.push({
                        id: null,
                        nombre: acab.categoria,
                        mts: 0,
                        sumaPrecio: parseFloat(acab.versiones[acab.versiones.length-1].precio)
                    });
                }
                // if (acab.categoria.toLowerCase().indexOf('mueble base') > -1  || dist.categoria[dist.categoria.length-1].nombre.toLowerCase().indexOf('mueble base') > -1) {
                //     console.log('acab', acab);
                // }
                // if(dist && dist.categoria[dist.categoria.length-1].nombre === 'INTERIORES') {
                //     console.log('distribucion', acab, acab.versiones[acab.versiones.length-1].precio);
                // }
            }
        };
        for (let i = 0; i <distribuciones.length; i ++) {
            const dist = distribuciones[i];
            let exist;
            exist = arrTotales.find(elem => elem.nombre.toLowerCase().indexOf(dist.categoria[0].nombre.toLowerCase()) > -1);
            if (exist) {
                const ancho = (dist.ancho > 1) ? dist.ancho : 1;
                exist.mts = exist.mts + (parseFloat(dist.largo)*parseFloat(ancho)); 
            }
        };
        let totalGlobal = 0;
        arrTotales = arrTotales.map( cat => {
            if(cat.nombre === 'INTERIORES') {
                console.log("sumaPrecio y precio base:");
                cat.mts += mtsPasillos;
                console.log(cat.sumaPrecio + " y " + this.getPrecioBase(cat.mts, preciosBase));
                cat.sumaPrecio = cat.sumaPrecio + this.getPrecioBase(cat.mts, preciosBase);
            }
            if(cat.nombre === 'INTERIORES - ATICO') {
                cat.sumaPrecio = arrTotales.find(elem => elem.nombre === 'INTERIORES').sumaPrecio/2;
            }
            const largoCocina = (distribuciones.some(elem => elem.nombre === 'COCINA')) ? parseFloat(distribuciones.find(elem => elem.nombre === 'COCINA').largo) : 0;
            const largoWC = (distribuciones.some(elem => elem.nombre.indexOf('WALKING CLOSSET') > -1)) ? parseFloat(distribuciones.find(elem => elem.nombre.indexOf('WALKING CLOSSET') > -1).largo) : 0;
            const anchoWC = (distribuciones.some(elem => elem.nombre.indexOf('WALKING CLOSSET') > -1)) ? parseFloat(distribuciones.find(elem => elem.nombre.indexOf('WALKING CLOSSET') > -1).ancho) : 0;
            const largoPrincipal = (distribuciones.some(elem => elem.nombre.indexOf('CUARTO PRINCIPAL') > -1)) ? parseFloat(distribuciones.find(elem => elem.nombre.indexOf('CUARTO PRINCIPAL') > -1).largo) : 0;
            const largosSecundarios = (distribuciones.some(elem => elem.nombre.indexOf('CUARTO') > -1 && elem.nombre.indexOf('SECUNDARIO') > -1)) ? distribuciones.filter(elem => elem.nombre.indexOf('CUARTO') > -1 && elem.nombre.indexOf('SECUNDARIO') > -1) : [];
            
            // to improve
            if(cat.nombre.toLowerCase().indexOf('mueble base') > -1) {
                cat.mts = largoCocina * 2;
            }
            else if(cat.nombre.toLowerCase().indexOf('mueble aéreo') > -1) {
                cat.mts = largoCocina;
            }
            else if(cat.nombre.toLowerCase().indexOf('walking closset') > -1) {
                cat.mts = largoWC + anchoWC;
            }
            else if(cat.nombre === 'closset principal') {
                cat.mts = largoPrincipal;
            }
            else if(cat.nombre === 'closset secundarios') {
                largosSecundarios.forEach(element => {
                    cat.mts += parseFloat(element.largo);
                });
            } else if (!cat.mts) {
                cat.mts = 1;
            }
            cat.sumaPrecio += typeof(proyecto.inflacionTipoSuelo) !== 'undefined' ? cat.sumaPrecio*(parseFloat(proyecto.inflacionTipoSuelo)/100) : 0;
            let inflacionUbicacion = proyecto.inflacionUbicacion.toString().split('-').length > 1 ? parseFloat(proyecto.inflacionUbicacion.toString().split('-')[1]) : parseFloat(proyecto.inflacionUbicacion.toString());
            console.log('inflacionUbicacion',inflacionUbicacion)
            cat.sumaPrecio += typeof(proyecto.inflacionUbicacion) !== 'undefined' ? cat.sumaPrecio*(inflacionUbicacion/100) : 0;

            const total = cat.sumaPrecio*cat.mts;
            cat.total = total;
            totalGlobal += total;
            return cat;
        });
        console.log('arrTotales',arrTotales);
        return totalGlobal;
    }

    getPrecioBase = (mts, preciosBase) => {
        let total = 0;
        switch (true) {
            case mts <= 90:
                total = parseFloat(preciosBase.data[0].precio);
                break;
            case mts > 90 && mts <= 120:
                total = parseFloat(preciosBase.data[1].precio);
                break;
            case mts > 120 && mts <= 150:
                total = parseFloat(preciosBase.data[2].precio);
                break;
            case mts > 150 && mts <= 200:
                total = parseFloat(preciosBase.data[3].precio);
                break;
            case mts > 200:
                total = parseFloat(preciosBase.data[4].precio);
                break;
        }
        return total;
    }

}