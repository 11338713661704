import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from "react-router-dom";
import * as QueryString from "query-string"
import axios from 'axios';
import Constants from '../../Constants';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';
import Step6 from './Step6/Step6';
import Step7 from './Step7/Step7';
import Step8 from './Step8/Step8';
import Step9 from './Step9/Step9';
import './AgregarProyecto.css';


function AgregarProyecto(props) {
    let { id } = useParams();
    let isInitial = true;
    const [currentStep, setCurrentStep] = useState(1);
    const [maxStep, setMaxtStep] = useState(1);
    const [userData, setUserData] = useState({});
    const [acabados, setAcabados] = useState([]);
    const [proyData, setProyData] = useState({
        fkIdVendedor: JSON.parse(localStorage.getItem('izqToken')).usuarioId,
        fkIdUsuario: '',
        fkIdSistemaConstructivo: '2',
        nombre: '',
        fechaIngreso: new Date(),
        tamano: '',
        presupuesto: '',
        monedaPresupuesto: '1',
        financiamiento: '',
        planos: '',
        lote: '',
        loteDeuda: '',
        montoDeuda: '',
        usoSuelos: '',
        disponibilidadAgua: '',
        fechaInicialProyectada: '',
        tipoProyecto: '',
        notas: '',
        servicio: '1',
        estudioSuelos: '',
        currentStep: 2
    })

    useEffect(() => {
        const params = QueryString.parse(props.location.search);
        if(params.alerta){
            let alerta = params.alerta;
            axios.get(Constants.api + '/v1/alerta/'+alerta)
            .then(res => {
                let a = res.data[0];
                a.revisada = 1;
                delete a['date'];
                delete a['rev'];
                delete a['tableData'];
                axios.put(Constants.api + '/v1/alerta/'+alerta, a)
                .then(res => {
                    
                })
            });
        }
    }, []);

    const componentToDisplay = () => {
        switch(currentStep){
            case 1:
                return <Step1 nextStep={nextStep} miProyecto={proyData} setProyecto={setProyData} setCurrentStep={selectInitialStep} setUserData={setUserData} setAcabados={setAcabados}></Step1>;
            case 2:
                return <Step2 nextStep={nextStep} goToOffer={goToOffer} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step2>;
            case 3:
                return <Step3 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step3>;
            case 4:
                return <Step4 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData} setAcabados={setAcabados}></Step4>;
            case 5:
                return <Step5 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData} userData={userData} acabados={acabados}></Step5>;
            case 6:
                return <Step6 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step6>;
            case 7:
                return <Step7 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step7>;
            case 8:
                return <Step8 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step8>;
            case 9:
                return <Step9 nextStep={nextStep} prevStep={prevStep} miProyecto={proyData} setProyecto={setProyData}></Step9>;
        }
        return <span>el</span>
    }

    const selectInitialStep = (step) => {
        if (maxStep < step) {
            setMaxtStep(step);
            setCurrentStep(step);
        }
    }

    const selectNewStep = (step) => {
        if (step <=maxStep) {
            setCurrentStep(step);
        }
    }

    const nextStep = () => {
        setCurrentStep(currentStep+1);
        if(currentStep+1 > maxStep) {
            setMaxtStep(currentStep+1);
        }
    }
    const goToOffer = () => {
        setCurrentStep(8);
        if(8 > maxStep) {
            setMaxtStep(8);
        }
    }

    const prevStep = () => {
        setCurrentStep(currentStep-1);
    }

    const checkActive = (step) => {
        return (step == currentStep) ? 'active' : '';
    }

    const checkActiveHigher = () => {
        return (currentStep < 6) ? 'active' : '';
    }

    const checkPassed = (step) => {
        return (step <= maxStep && step !== currentStep) ? 'passed' : '';
    }

    const checkPassedHigher = (step) => {
        return (currentStep > 5) ? 'passed' : '';
    }

    const isEstimacion = () => {
        return currentStep < 6;
    }

    return (
        <div>
            <div className="table-container">
                <h3>Proyecto {id} - {userData.nombre}</h3>
                <div className="multi-step">
                    <div onClick={()=>selectNewStep(1)} className={`step-selector purple ${checkActiveHigher()} ${checkPassedHigher()}`}>
                       <div className="inner-circle"><span>Estimación</span></div>
                    </div>
                    <div className={`join purple ${checkActive(6)} ${checkPassed(6)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(6)} className={`step-selector purple ${checkActive(6)} ${checkPassed(6)}`}>
                       <div className="inner-circle"><span>Notas</span></div>
                    </div>
                    <div className={`join purple ${checkActive(7)} ${checkPassed(7)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(7)} className={`step-selector purple ${checkActive(7)} ${checkPassed(7)}`}>
                       <div className="inner-circle"><span>Diseño</span></div>
                    </div>
                    <div className={`join purple ${checkActive(8)} ${checkPassed(8)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(8)} className={`step-selector purple ${checkActive(8)} ${checkPassed(8)}`}>
                       <div className="inner-circle"><span>Oferta</span></div>
                    </div>
                    <div className={`join purple ${checkActive(9)} ${checkPassed(9)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(9)} className={`step-selector purple ${checkActive(9)} ${checkPassed(9)}`}>
                       <div className="inner-circle"><span>Final</span></div>
                    </div>
                </div>
                {isEstimacion() && <div className="multi-step">
                    <div onClick={()=>selectNewStep(1)} className={`step-selector purple ${checkActive(1)} ${checkPassed(1)}`}>
                       <div className="inner-circle"><span>Cliente</span></div>
                    </div>
                    <div className={`join purple ${checkActive(2)} ${checkPassed(2)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(2)} className={`step-selector purple ${checkActive(2)} ${checkPassed(2)}`}>
                       <div className="inner-circle"><span>Proyecto</span></div>
                    </div>
                    <div className={`join purple ${checkActive(3)} ${checkPassed(3)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(3)} className={`step-selector purple ${checkActive(3)} ${checkPassed(3)}`}>
                       <div className="inner-circle"><span>Distribución</span></div>
                    </div>
                    <div className={`join purple ${checkActive(4)} ${checkPassed(4)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(4)} className={`step-selector purple ${checkActive(4)} ${checkPassed(4)}`}>
                       <div className="inner-circle"><span>Acabados</span></div>
                    </div>
                    <div className={`join purple ${checkActive(5)} ${checkPassed(5)}`}>
                    </div>
                    <div onClick={()=>selectNewStep(5)} className={`step-selector purple ${checkActive(5)} ${checkPassed(5)}`}>
                       <div className="inner-circle"><span>Estimación</span></div>
                    </div>
                </div>}
                {componentToDisplay()}
            </div>
        </div>
    );
}

export default withRouter(AgregarProyecto);