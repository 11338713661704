import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import './Step4.css';

function Step4(props) {
    const [userData, setUserData] = useState({});
    const [acabados, setAcabados] = useState([]);
    const [acabadosSaved, setAcabadosSaved] = useState([]);
    const [openWheel, setWheel] = useState(false);

    useEffect(() => {
        getAcabados(acabados,acabadosSaved);
    }, []);

    const onSubmit = () => {
        console.log('acabados',acabados);
        let goSave = true;
        setWheel(true);
        for (let i = 0; i < acabados.length; i++) {
            if (!acabados[i][1].some(elem => elem.selected === true)) {
                goSave = false;
            }
        }
        if (goSave) {
            const bodyP = JSON.parse(JSON.stringify(props.miProyecto));
            delete bodyP['comprador'];
            delete bodyP['vendedor'];
            delete bodyP['estimador'];
            bodyP.currentStep = 5;
            
            axios.put(Constants.api+'/v1/proyecto/'+props.miProyecto.pkIdProyecto, 
            bodyP)
            .then((resp)=>{
                setWheel(false);
                props.nextStep();
                props.setProyecto(bodyP);
            }); 
        } else {
            setWheel(false);
            alert ("Debe seleccionar almenos una opción para todas las categorías"); 
            return false;
        }
    }
    const checkStyle = (value) => {
		return value ? "error-input" : '';
    }
    const getAcabados = async () => {
        setWheel(true);
        let arrReq = [];
        arrReq.push(axios.get(Constants.api+'/v1/acabado'));
        arrReq.push(axios.get(Constants.api+'/v1/version_acabado_proyecto?proyecto='+props.miProyecto.pkIdProyecto));
        Promise.all(arrReq).then(resp =>{
            const verAcabProy = resp[1].data;
            const acabados = resp[0].data.sort(
                (a, b) => (a["rankingCategoria"] - b["rankingCategoria"] || a["ranking"] - b["ranking"]));
            const acabadosMap = acabados.map(ac => {
                ac.selected = ac.versiones.some(version => verAcabProy.some(verAc => verAc.fkIdVersionAcabado === version.pkIdVersionAcabado && verAc.fkIdProyecto == props.miProyecto.pkIdProyecto));
                const version = ac.versiones.find(version => verAcabProy.some(verAc => verAc.fkIdVersionAcabado === version.pkIdVersionAcabado && verAc.fkIdProyecto == props.miProyecto.pkIdProyecto));
                if (ac.selected) {
                    // verAcabProy.filter(verAc => verAc.fkIdVersionAcabado === ac.versiones[ac.versiones.length-1].pkIdVersionAcabado && verAc.fkIdProyecto == props.miProyecto.pkIdProyecto).forEach(element => {
                    //     axios.delete(Constants.api+'/v1/version_acabado_proyecto/'+element.pkIdAcabadoProyecto);
                    // });
                    ac.pkIdAcabadoProyecto = verAcabProy.filter(verAc => verAc.fkIdVersionAcabado === version.pkIdVersionAcabado && verAc.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].pkIdAcabadoProyecto;
                }
                return ac;
            });
            const result = Object.entries(groupBy(acabadosMap, (c) => c.categoria));
            console.log('result',result)
            // for (let i = 0; i < result.length; i++) {
            //     if (!result[i][1].some(elem => elem.selected === true)) {
            //         changeItem(result, i, 0)
            //     }
            // }
            setAcabados(result);
            setWheel(false);
            setAcabadosSaved(verAcabProy);
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		//this.context.logOut();
	    	}
        });
    }
    const groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    }
    const changeItem = async (acabados, index, indexA) => {
        let proy = JSON.parse(JSON.stringify(acabados));
        console.log('proy[index]',proy[index]);
        let objToSave ={
            fkIdProyecto: props.miProyecto.pkIdProyecto, 
            fkIdVersionAcabado:proy[index][1][indexA].versiones[proy[index][1][indexA].versiones.length-1]?.pkIdVersionAcabado
        }
        console.log('proy[index][1][indexA]',proy[index][1][indexA]);
        let borrar = proy[index][1][indexA].selected;
        if (objToSave.fkIdVersionAcabado) {
            proy[index][1].forEach(element => {
                element.selected = false;
                if (element.pkIdAcabadoProyecto) {
                    axios.delete(Constants.api+'/v1/version_acabado_proyecto/'+element.pkIdAcabadoProyecto);
                }
            });
            if (!borrar) {
                proy[index][1][indexA].selected = true;
                axios.post(Constants.api+'/v1/version_acabado_proyecto', objToSave).then((resp)=>{	
                    proy[index][1][indexA].pkIdAcabadoProyecto = resp.data[0].insertId;
                });
            }
            setAcabados(proy);
        }
	}

    return (
        <div className="form-container">
            <div>
                <Formik
                        enableReinitialize
                        initialValues={userData}
                        validateOnChange={false}
                        // validationSchema={Yup.object().shape({
                        //     nombre: Yup.string()
                        //         .required('Campo requerido'),
                        //     telefono: Yup.string()
                        //         .required('Campo requerido'),
                        //     correo: Yup.string()
                        //         .required('Campo requerido')
                        // })}
                        onSubmit={onSubmit}
                    >
                    {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} validate="true">

                            {acabados.map((obj, index) => {
                                return (
                                    <div>
                                        <h3>{obj[0]}</h3>
                                        <div className="form-group acabados-container">
                                        {obj[1].map((acabado, indexA) => {
                                            return (
                                            <div className={`acabado ${acabado.selected? 'selected': ''}`} onClick={(e) => changeItem(acabados, index, indexA)}>
                                                <img src={acabado.imgUrl}/>
                                                <span>{acabado.nombre}</span>
                                            </div>
                                            );
                                        })}    
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="form-group buttons">
                                <button disabled={isSubmitting} className="btn btn-cancel" onClick={props.prevStep}>
								Atrás
								</button>
                                <button type="submit" disabled={isSubmitting} className="btn btn-save">
                                Siguiente
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
			{openWheel && <Wheel/>}
        </div>
    );
}

export default Step4;