import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Constants from '../../../Constants';
import axios from 'axios';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import './Step2.css';

function Step2(props) {

    const [openWheel, setWheel] = useState(false);
    const [proyData, setProyData] = useState({
        fkIdVendedor: JSON.parse(localStorage.getItem('izqToken')).usuarioId,
        fkIdUsuario: '',
        fkIdSistemaConstructivo: '2',
        nombre: '',
        fechaIngreso: '2020-05-17T00:00:00.000Z',
        tamano: '',
        presupuesto: '',
        monedaPresupuesto: '1',
        financiamiento: '',
        planos: '',
        lote: '',
        loteDeuda: '',
        montoDeuda: '',
        usoSuelos: '',
        disponibilidadAgua: '',
        fechaInicialProyectada: '',
        tipoProyecto: '',
        notas: '',
        servicio: '1',
        estudioSuelos: ''
    });
    const [promociones, setPromociones] = useState([]);

    useEffect(() => {
        //changeSelect(e.target.value, 'servicio', values)
        setProyData(props.miProyecto);

    }, []);

    const onSubmit = async (values, {setSubmitting, resetForm}) => {

		const body = JSON.parse(JSON.stringify(values));
        setWheel(true);
        delete body['comprador'];
        delete body['vendedor'];
        delete body['estimador'];
        body.presupuesto = body.presupuesto ? parseInt(body.presupuesto.toString().replace(/,/g, '')) : '';
        body.montoDeuda = body.montoDeuda? parseInt(body.montoDeuda.toString().replace(/,/g, '')) : '';
        body.servicio = body.servicio? body.servicio : 1;
        if (3 > body.currentStep) {
            body.currentStep = 3;
        }
        console.log('body',body)

        if (parseInt(body.servicio) > 1 && 8 > body.currentStep) {
            body.currentStep = 8;
        }
        await axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
        body)
        .then(async (resp)=>{
            setWheel(false);
            if (parseInt(body.servicio) === 1 || body.servicio === null) {
                body.servicio = 1;
                props.nextStep();
            } else {
                props.goToOffer();
            }
            props.setProyecto(body);
        });
    }

    const numberWithCommas = (x) => {
        x = x? parseInt(x.toString().replace(/,/g, '')): '';
        return x? x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):'';
    }

    const checkStyle = (value) => {
		return value ? "error-input" : '';
    }
    const changeSelect = (val, key, values) => {
        let proy = JSON.parse(JSON.stringify(values));
		proy[key] = val;
		setProyData(proy);
	}

    return (
        <div className="form-container">
            <div className="big-container">
                <h3>Datos del proyecto:</h3>
                <Formik
                        enableReinitialize
                        initialValues={proyData}
                        validateOnChange={false}
                        validationSchema={Yup.object().shape({
                            tamano: Yup.string()
                                .required('Campo requerido'),
                            tipoProyecto: Yup.string()
                                .required('Campo requerido'),
                            lote: Yup.string()
                                .required('Campo requerido'),
                            fechaInicialProyectada: Yup.string()
                                .required('Campo requerido')
                        })}
                        onSubmit={onSubmit}
                    >
                    {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} validate="true">
                            <div className="data-column">
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.tamano)}`} name="tamano" onChange={handleChange} value={values.tamano} label="Metros Cuadrados"/>
                                    <p className="invalid-feedback add-form">{errors.tamano}</p>
                                </div>
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.presupuesto)}`} name="presupuesto" onChange={handleChange} value={numberWithCommas(values.presupuesto)} label="Presupuesto"/>
                                    <p className="invalid-feedback add-form">{errors.presupuesto}</p>
                                </div>
                                <div className="form-group">
                                    <span>Moneda del presupuesto</span>
                                    <select value={values.monedaPresupuesto} onChange={(e) => changeSelect(e.target.value, 'monedaPresupuesto', values)}>
                                        <option key="1" value="1">Colones</option>
                                        <option key="2" value="2">US Dolar</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.tipoProyecto)}`} name="tipoProyecto" onChange={handleChange} value={values.tipoProyecto} label="Tipo de Proyecto"/>
                                    <p className="invalid-feedback add-form">{errors.tipoProyecto}</p>
                                </div>
                                <div className="form-group">
                                    <span>Que servicio desea?</span>
                                    <select value={values.servicio} onChange={(e) => changeSelect(e.target.value, 'servicio', values)}>
                                        <option key="1" value="1">Planos y construcción</option>
                                        <option key="2" value="2">Solo planos</option>
                                        <option key="3" value="3">Solo construcción</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.lote)}`} name="lote" onChange={handleChange} value={values.lote} label="POSEE LOTE/ PLANO CATASTRO/ m2/ UBICACION?"/>
                                    <p className="invalid-feedback add-form">{errors.lote}</p>
                                </div>
                            </div>

                            <div className="data-column">
                                <br/>
                                <div className="form-group">
                                    <span>EL LOTE ESTA LIBRE? O TIENE DEUDA?</span>
                                    <select value={values.loteDeuda} onChange={(e) => changeSelect(e.target.value, 'loteDeuda', values)}>
                                        <option key="1" value="1">Libre</option>
                                        <option key="2" value="2">Deuda</option>
                                        <option key="3" value="3">N/A</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.montoDeuda)}`} name="montoDeuda" onChange={handleChange} value={numberWithCommas(values.montoDeuda)} label="Monto Deuda"/>
                                    <p className="invalid-feedback add-form">{errors.montoDeuda}</p>
                                </div>
                                <div className="form-group">
                                    <span>TIENE EL USO DE SUELOS? / TIENE CARTA DE DISPONIBILIDAD DE AGUA?</span>
                                    <select value={values.usoSuelos} onChange={(e) => changeSelect(e.target.value, 'usoSuelos', values)}>
                                        <option key="1" value="1">USO SI - CARTA SI</option>
                                        <option key="2" value="2">USO SI - CARTA NO</option>
                                        <option key="3" value="3">USO NO - CARTA NO</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <span>CUENTA CON ESTUDIO DE SUELOS?</span>
                                    <select value={values.estudioSuelos} onChange={(e) => changeSelect(e.target.value, 'estudioSuelos', values)}>
                                        <option key="1" value="1">SI</option>
                                        <option key="2" value="2">NO</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <TextField className={`input-form ${checkStyle(errors.fechaInicialProyectada)}`} name="fechaInicialProyectada" onChange={handleChange} value={values.fechaInicialProyectada} label="EN CUANTO TIEMPO DESEA INICIAR EL PROYECTO?"/>
                                    <p className="invalid-feedback add-form">{errors.fechaInicialProyectada}</p>
                                </div>
                                <div className="form-group">
                                    <span>Notas:</span>
                                    <TextField className={`input-form ${checkStyle(errors.notas)}`}  value={values.notas} name="notas" onChange={handleChange}/>
                                    <p className="invalid-feedback add-form">{errors.notas}</p>
                                </div>
                            </div>

                            <div className="form-group buttons">
                                <button disabled={isSubmitting} className="btn btn-cancel" onClick={props.prevStep}>
								Atrás
								</button>
                                <button type="submit" disabled={isSubmitting} className="btn btn-save">
                                Siguiente
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

			{openWheel && <Wheel/>}
        </div>
    );
}

export default Step2;