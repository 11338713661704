import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import './Wheel.css';

class Wheel extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      	open: false
	    }
	}
    render() {

		const useStyles = makeStyles((theme) => ({
			root: {
				display: 'flex',
				'& > * + *': {
				marginLeft: theme.spacing(2),
				},
			},
		}))

        return (
            <div className="wheel-container">
				<div className={useStyles.root}>
					<CircularProgress color="secondary" />
				</div>
            </div>
        );
    }
}

export default Wheel;