import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Constants from '../../../Constants';
import UsuarioArchivos from './UsuarioArchivos/UsuarioArchivos'; 

let sub;
let cedulaToAdd = null;
let eticasToAdd = [];
let confsToAdd = [];
let adendumsToAdd = [];
let contratoToAdd = null;
let user = null;

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

const Archivos = (props) => {
    let [newContrato, setNewContrato] = useState(null);
    let [newAdendums, setNewAdendums] = useState([]);
    let [newConfs, setNewConfs] = useState([]);
    let [newEticas, setNewEticas] = useState([]);
    let [newCedula, setNewCedula] = useState(null);
    let [contrato, setContrato] = useState(null);// unico
    let [adendums, setAdendum] = useState([]);// multi
    let [confs, setConf] = useState([]);// multi
    let [eticas, setEtica] = useState([]);// multi
    let [cedula, setCedula] = useState(null);// unico

    const loadFiles = (user) => {
        axios.get(Constants.api+`/v1/file-upload?path=files/user/${user.pkIdUsuario}/contrato`)
        .then(res => {
            setContrato(res.data.Contents[0]);
        });

		axios.get(Constants.api+`/v1/file-upload?path=files/user/${user.pkIdUsuario}/adendum/`)
        .then(res => {
            setAdendum(res.data.Contents);
        });

        axios.get(Constants.api+`/v1/file-upload?path=files/user/${user.pkIdUsuario}/confidencialidad/`)
        .then(res => {
            setConf(res.data.Contents);
        });

        axios.get(Constants.api+`/v1/file-upload?path=files/user/${user.pkIdUsuario}/etica/`)
        .then(res => {
            setEtica(res.data.Contents);
        });

        axios.get(Constants.api+`/v1/file-upload?path=files/user/${user.pkIdUsuario}/cedula`)
        .then(res => {
            setCedula(res.data.Contents[0]);
        });
    }
    
    const guardaArchivos = async (action) => {
        let filesToUpload = [];
		if(contratoToAdd) {
			const fileData = await toBase64(contratoToAdd);
			filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
				{
					fileName: 'contrato',
					filePath: `files/user/${user.pkIdUsuario}/`,
					contentType: contratoToAdd.type,
					file: fileData.replace(/^data:.+;base64,/, '')
				}).catch(error => {
                    alert("Error al subir el archivo");
                }));
		}
		if(adendumsToAdd.length > 0) {
            for(let i = 0; i < adendumsToAdd.length; i++){
                const file = adendumsToAdd[i];
                console.log('adendums');
                const fileData = await toBase64(file);
                console.log('after');
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/user/${user.pkIdUsuario}/adendum/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }).catch(error => {
                    alert("Error al subir el archivo");
                }));
            }
		}
		if(confsToAdd.length > 0) {
            for(let i = 0; i < confsToAdd.length; i++){
                const file = confsToAdd[i];
                const fileData = await toBase64(file);
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/user/${user.pkIdUsuario}/confidencialidad/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }).catch(error => {
                    alert("Error al subir el archivo");
                }));
            }
		}
		if(eticasToAdd.length > 0) {
            for(let i = 0; i < eticasToAdd.length; i++){
                const file = eticasToAdd[i];
                const fileData = await toBase64(file);
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/user/${user.pkIdUsuario}/etica/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }).catch(error => {
                    alert("Error al subir el archivo");
                }));
            }
        }
		if(cedulaToAdd) {
			const fileData = await toBase64(cedulaToAdd);
			filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
				{
					fileName: 'cedula',
					filePath: `files/user/${user.pkIdUsuario}/`,
					contentType: cedulaToAdd.type,
					file: fileData.replace(/^data:.+;base64,/, '')
				}).catch(error => {
                    alert("Error al subir el archivo");
                }));
        }
        if(filesToUpload.length > 0) {
            await Promise.all(filesToUpload);
        }
        if(cedulaToAdd || contratoToAdd || adendumsToAdd.length > 0 || confsToAdd.length > 0 || eticasToAdd.length > 0){
            setNewContrato(null);
            contratoToAdd = null;
            setAdendum([]);
            adendumsToAdd = [];
            setNewConfs([]);
            confsToAdd = [];
            setNewEticas([]);
            eticasToAdd = [];
            setNewCedula(null);
            cedulaToAdd = null;
        }
        action.next('done');
    }
    
    const seleccionarArchivo = (file, type) => {
        console.log('selectFile', type);
        if(type === 'contrato'){
            setNewContrato(file);
            contratoToAdd = file;
        }
        if(type === 'adendum'){
            setNewAdendums([...newAdendums, file]);
            adendumsToAdd = [...newAdendums, file];
        }
        if(type === 'confidencialidad'){
            setNewConfs([...newConfs, file]);
            confsToAdd = [...newConfs, file];
        }
        if(type === 'etica'){
            setNewEticas([...newEticas, file]);
            eticasToAdd = [...newEticas, file];
        }
        if(type === 'cedula'){
            setNewCedula(file);
            cedulaToAdd = file;
        }
    }

    const borrarArchivo = file => {
        axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
                        .then(succ => {
                            loadFiles(props.user);
                        })

    }


    useEffect(() => {
        console.log(props);
        if(props.user.pkIdUsuario){
            user = props.user;
            loadFiles(props.user);
        }
        if(!sub){
            sub = props.saveAction.asObservable().subscribe(async ([action, addedUser = null]) => {
                if (action) {
                    if(addedUser) user = addedUser;
                    await guardaArchivos(action);
                }
            });
        }
    },[props]);

    return (
        <div>
            <UsuarioArchivos type="Contrato" seleccionarArchivo={(e) => seleccionarArchivo(e, 'contrato')}> </UsuarioArchivos>
            {newContrato && <div>
                Archivos a agregar:
                <ul>
                    <li><span key={newContrato.name}>{newContrato.name}</span></li>
                </ul>
            </div>}
            {contrato && <div>
                Contrato asignado al usuario:
                <ul>
                    <li>
                        <a href={Constants.imgHost + contrato.Key} key={contrato.Key} target="_blank">{contrato.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(contrato)}} className="delete-file">Borrar Archivo</span>
                    </li>
                </ul>
            </div>}
            <UsuarioArchivos type="Adendum" seleccionarArchivo={(e) => seleccionarArchivo(e, 'adendum')}> </UsuarioArchivos>
            {newAdendums.length > 0 && <div>
                Archivos a agregar:
                <ul>
                    {newAdendums.map(ad => {
                        return <li><span key={ad.name}>{ad.name}</span></li>
                    })}
                </ul>
            </div>}
            {adendums.length > 0 && <div>
                Adendums asignados al usuario:
                <ul>
                    {adendums.map(ad => {
                        return (
                            <li>
                                <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank">{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            <UsuarioArchivos type="Acuerdo de confidencialidad" seleccionarArchivo={(e) => seleccionarArchivo(e, 'confidencialidad')}> </UsuarioArchivos>
            {newConfs.length > 0 && <div>
                Archivos a agregar:
                <ul>
                    {newConfs.map(ad => {
                        return <li><span key={ad.name}>{ad.name}</span></li>
                    })}
                </ul>
            </div>}
            {confs.length > 0 && <div>
                Acuerdo de confidencialidad asignado al usuario:
                <ul>
                    {confs.map(ad => {
                        return (
                            <li>
                                <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank">{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            <UsuarioArchivos type="Acuerdo de ética" seleccionarArchivo={(e) => seleccionarArchivo(e, 'etica')}> </UsuarioArchivos>
            {newEticas.length > 0 && <div>
                Archivos a agregar:
                <ul>
                    {newEticas.map(ad => {
                        return <li><span key={ad.name}>{ad.name}</span></li>
                    })} 
                </ul>
            </div>}
            {eticas.length > 0  && <div>
                Acuerdo de ética asignado al usuario:
                <ul>
                    {eticas.map(ad => {
                        return (
                            <li>
                                <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank">{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            <UsuarioArchivos type="Cédula" seleccionarArchivo={(e) => seleccionarArchivo(e, 'cedula')}> </UsuarioArchivos>
            {newCedula && <div>
                Archivos a agregar:
                <ul>
                    <li><span key={newCedula.name}>{newCedula.name}</span></li>
                </ul>
            </div>}
            {cedula && <div>
                Cédula asignado al usuario:
                <ul>
                    <li>
                        <a href={Constants.imgHost + cedula.Key} key={cedula.Key} target="_blank">{cedula.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(cedula)}} className="delete-file">Borrar Archivo</span>
                    </li>
                </ul>
            </div>}
        </div>
    );
};

export default Archivos;