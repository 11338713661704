import React, {useState, useEffect, useContext} from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import Oferta from './Oferta/Oferta';
import MainContext from '../../../MainContext';
import * as QueryString from "query-string"
import './Step8.css'

let ofertasToAdd = [];

function Step8(props) {
    let ctx = useContext(MainContext);
    const [alerta, setAlerta] = useState();
    const [openWheel, setWheel] = useState(false);
    let [ofertas, setOfertas] = useState([]);
    let [selectedOferta, setSelectedOferta] = useState({});
    let [disenadores, setDisenadores] = useState([]);
    let [disenadorSelected, setDisenadorSelected] = useState(null);

    useEffect(() => {
        getOfertas();
        const params = QueryString.parse(props.location.search);
        if(params.alerta){
            setAlerta(params.alerta);
        }
        cargaDisenadores();
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        setDisenadorSelected(proyData.fkIdGerente)
    },[])

    const getOfertas = () => {
        axios.get(Constants.api+`/v1/oferta?proyecto=${props.miProyecto.pkIdProyecto}`)
        .then(res => {
            setSelectedOferta({});
            setOfertas(res.data.reverse());
        });
    }

    const cargaDisenadores = () => {
        axios.get(Constants.api+`/v1/usuario?type=gerentes`)
        .then(res => {
            console.log(res);
            setDisenadores(res.data);
        });
    }

    const formatDate = (d) => {
        return new Date(d).toISOString().split('T')[0];
    }

    const aceptarOferta = () => {
        setWheel(true);
        // if(alerta){
        //     axios.get(Constants.api + '/v1/alerta/'+alerta)
        //     .then(res => {
        //         let a = res.data[0];
        //         a.revisada = 1;
        //         delete a['date'];
        //         delete a['rev'];
        //         delete a['tableData'];
        //         axios.put(Constants.api + '/v1/alerta/'+alerta, a)
        //         .then(res => {
                    
        //         })
        //     });
        // }
        let of = JSON.parse(JSON.stringify(ofertas[0]));
        of.estado = 3; //aprobada por gerencia
        of.aprobadaCliente = 1;
        axios.put(Constants.api+`/v1/oferta/${of.pkIdOferta}`,of)
        .then(res => {
            console.log('oferta lista');
        });

        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdVendedor)
                    .then(vend => {
                        const disenador = disenadores.find(d => d.pkIdUsuario ===  Number(proyData.fkIdGerente));
                        let email = {
                            emailFiles: [],
                            emailBody:{
                                from: disenador.correo,
                                to: [vend.data[0].correos[0].correo],
                                subject: 'Oferta aprobada por gerente',
                                body: 'Oferta aprobada por gerente',
                                type: 'template',
                                name: 'gerencia-revisado',
                                mappings: {
                                    nombregerente: `${disenador.nombre} ${disenador.apellido1} ${disenador.apellido2}`,
                                    nombrevendedor: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                    nombreproyecto: 'Proyecto número ' + proyData.pkIdProyecto,
                                    linkproyecto: `${Constants.app}/proyectos/${proyData.pkIdProyecto}`,
                                    imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdDisenador}/firma" alt="firma" width="433"/>`
                                }
                            }
                        }
                        axios.post(Constants.api+'/v1/send_mail/', email)
                        .then(res => {
                            setWheel(false);
                        });
                    });
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        proyData.currentStep = 9;
        axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
            proyData)
        .then(async (resp)=> {
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            proyData.currentStep = 9;
            props.setProyecto(proyData);
            setWheel(false);
            props.nextStep();
        });
    }

    const solicitarAprobacion = () => {
        console.log(disenadorSelected);
        if(disenadorSelected){
            setWheel(true);
            let of = JSON.parse(JSON.stringify(ofertas[0]));
            axios.post(Constants.api+'/v1/alerta', 
                {
                    fkIdPermiso: 13,
                    mensaje:'Oferta ' + props.miProyecto.comprador[0].nombre +' requiere aprobación',
                    revisada: 0,
                    link: '/proyectos/'+props.miProyecto.pkIdProyecto
                })
            .then(async (resp)=> {
                console.log('resp, alertas', resp);
            });
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdUsuarioEstimador)
                        .then(vend => {
                            const disenador = disenadores.find(d => d.pkIdUsuario ===  Number(disenadorSelected));
                            let email = {
                                emailFiles: [],
                                emailBody:{
                                    from: vend.data[0].correos[0].correo,
                                    to: [disenador.correo],
                                    subject: 'Oferta ' + props.miProyecto.comprador[0].nombre +' requiere aprobación',
                                    body: 'Oferta ' + props.miProyecto.comprador[0].nombre +' requiere aprobación',
                                    type: 'template',
                                    name: 'listo-para-gerente',
                                    mappings: {
                                        nombregerente: `${disenador.nombre} ${disenador.apellido1} ${disenador.apellido2}`,
                                        nombrepresupuestista: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                        nombreproyecto: 'Proyecto número ' + proyData.pkIdProyecto,
                                        linkproyecto: `${Constants.app}/proyectos/${proyData.pkIdProyecto}`,
                                        imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                                    }
                                }
                            }
                            axios.post(Constants.api+'/v1/send_mail/', email)
                            .then(res => {console.log('email sent')});
                        });
            // let of = JSON.parse(JSON.stringify(o));
            of.estado = 2;
            axios.put(Constants.api+`/v1/oferta/${of.pkIdOferta}`,of)
            .then(res => {
                setWheel(false);
                getOfertas();
            });
            delete proyData['comprador'];
            delete proyData['vendedor'];
            delete proyData['estimador'];
            proyData.fkIdGerente = Number(disenadorSelected);
            axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
                proyData)
            .then(async (resp)=> {
                let proyData = JSON.parse(JSON.stringify(props.miProyecto));
                proyData.fkIdGerente = Number(disenadorSelected);
                props.setProyecto(proyData);
                // setWheel(false);
                // props.nextStep();
            });
        } else {
            alert('Por favor seleccione un gerente para asignarle la oferta')
        }
    }

    const cambio = () => {
        console.log('alerta',alerta)
        if(alerta){
            axios.get(Constants.api + '/v1/alerta/'+alerta)
            .then(res => {
                let a = res.data[0];
                a.revisada = 1;
                delete a['date'];
                delete a['rev'];
                delete a['tableData'];
                axios.put(Constants.api + '/v1/alerta/'+alerta, a)
                .then(res => {
                    
                })
            });
        }
        const selectedCambio = document.getElementById('cambio').value;
        console.log(selectedCambio);
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        if(selectedCambio == 1) {
            proyData.currentStep = 6;
        } else if(selectedCambio == 2) {
            proyData.currentStep = 3;
        } else {
            proyData.clienteNoContinua = 1;
        }
        axios.put(Constants.api+`/v1/proyecto/${proyData.pkIdProyecto}`,proyData)
        .then(res => {
            setWheel(false);
            getOfertas();
            window.reload();
        });
    }

    const changeDisenador = (u) => {
        setDisenadorSelected(u);
    }

    return (
        <div>
            <div>
                <div className="form-container">
                    <div>
                        <h3>Ofertas</h3><span className="nueva-oferta" href="" onClick={()=>setSelectedOferta({})}>Crear nueva oferta</span>
                        <ul className="ofertas">
                            {ofertas.map((o, index)=>{
                                if(index === 0){
                                    return <li className={o.pkIdOferta === selectedOferta.pkIdOferta ? 'selected': ''}>Precio: {o.oferta} - Fecha: {formatDate(o.fechaAgregado)} <span className="action" onClick={()=>setSelectedOferta(o)}>Ver oferta</span> {o.estado == 2 && <span> --- Ya esta oferta fue enviada a gerencia.</span>} {o.estado == 3 && <span>Ya esta oferta fue aprobada por gerencia.</span>}</li>
                                } else {
                                    return <li className={o.pkIdOferta === selectedOferta.pkIdOferta ? 'selected': ''}><span className="oferta-vieja-text">Precio: {o.oferta} - Fecha: {formatDate(o.fechaAgregado)}</span> <span className="action" onClick={()=>setSelectedOferta(o)}>Ver oferta</span> <span className="oferta-vieja">Esta es una oferta antigua.</span></li>
                                }
                            })}
                             
                        </ul>
                        <div className="cambios">
                            <span>Cambiar proyecto a </span>
                            <select id="cambio">
                                <option value="0">Continuar</option>
                                <option value="1">Diseño</option>
                                <option value="2">Estimación</option>
                                <option value="3">No desea continuar con el proyecto</option>
                            </select>
                        </div>
                        {/* {props.miProyecto.estimador && props.miProyecto.estimador.length > 0 && <div>
                            La oferta fue enviada al cliente por: {props.miProyecto.estimador[0].nombre + ' ' + props.miProyecto.estimador[0].apellido1}
                        </div>} */}
                    </div>
                    <div className="cont">
                        <div className="oferta-cont">
                            <Oferta o={selectedOferta} miProyecto={props.miProyecto} reloadOfertas={getOfertas}></Oferta>
                        </div>
                    </div>
                </div>
                <div className="form-container">
                    <div className="form-group">
                        <span>Gerentes</span>
                        <select value={disenadorSelected} name="disenadorSelected" onChange={(e) => changeDisenador(e.target.value)}>
                            <option key="0" value="">Seleccione el Gerente</option>
                            {disenadores.map((d) => <option key={d.fkIdUsuario} value={d.fkIdUsuario}>{d.nombre} {d.apellido1}</option>)}
                        </select>
                    </div>
                </div>
                <div className="form-container">
                    <div>
                        <button className="btn btn-cancel" onClick={props.prevStep}>
                            Atrás
                        </button>
                        <button className="btn btn-cancel" onClick={cambio}>
                            Efectuar cambio
                        </button>
                        {ctx.hasPermission('crear-oferta') && ofertas.length > 0 && ofertas[0]?.estado == 1 && <button type="submit" className="btn btn-save" onClick={solicitarAprobacion}>
                            Enviar oferta mas reciente para revisión de gerencia
                        </button>}
                        {ctx.hasPermission('verificar-estimaciones') && ofertas[0]?.estado == 2 && <button type="submit" className="btn btn-save" onClick={aceptarOferta}>
                            Aprobar oferta mas reciente
                        </button>}
                    </div>
                </div>
                {openWheel && <Wheel/>}
            </div>
            {/* }
            {!ctx.hasPermission('crear-oferta') && <div>No tiene permisos para ver esta pantalla</div>} */}
        </div>
    );
}

export default withRouter(Step8);