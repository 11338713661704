import React, { Component } from 'react';
import './AgregarUsuarios.css';
import axios from 'axios';
import { Formik, ErrorMessage, FieldArray} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../MainContext';
import UserIcon from '../UserIcon/UserIcon';
import Constants from '../../Constants';
import GenericAlert from '../../shared/dialog/generic-alert/Generic-Alert';
import Salarios from './Salarios/Salarios';
import SalariosLocal from './SalariosLocal/SalariosLocal';
import Password from './Password/Password';
import Archivos from './Archivos/Archivos';
import Wheel from '../../shared/dialog/wheel/Wheel';
import { Subject } from 'rxjs';

const saveAction = new Subject();

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

let errorRol = "";

class AgregarUsuarios extends Component {
	selectedFile = null;
	selectedFirma = null;

	constructor(props) {
	    super(props);
	    this.state = {
	        hasError: false,
	        myUser: {
	        	apellido1: '',
				apellido2: '',
				cedula: '',
				clave: '',
				fkIdRol: '',
				ingresoFamiliar: '',
				nombre: '',
				ocupacion: '',
				fechaNacimiento: '',
				fechaIngreso: '',
				ultimoLogin: null,
				usuario: '',
				correos: [{
		            correo: ''
	            }],
				telefonos: [{
						telefono: ''
					},
					{
						telefono: ''
	            	}],
				salarios:[{
					salario: ''
				}]
			},
			file: null,
			selectedArchivos: [],
			contrato: null,
			contratoStored: null,
			adendum: null,
			ademdumStored: null,
			confidencialidad: null,
			confidencialidadStored: null,
			etica:null,
			eticaStored: null,
			cedula: null,
			cedulaStored: null,
			userArchivos: [],
			rolList: [],
			isAlertOpen: false,
			isPassOpen: false,
			openWheel: false,
			selectedUser: {},
			salarios: [],
			vendedores: []
	    }
	}

	hasFilesToUpload = () => {
		return (this.state.contrato || 
			this.state.adendum ||
			this.state.confidencialidad ||
			this.state.etica ||
			this.state.cedula);
	}

	onSubmit = async (values, {setSubmitting, resetForm}) => {
		if (this.state.myUser.fkIdRol) {
			this.setState({
				openWheel: true
			});
			errorRol = "";
			let postImgRes;
			if (this.selectedFile) {
				const file = await toBase64(this.selectedFile);
				postImgRes = await axios.post(Constants.api+'/v1/file-upload',
					{
						fileName: this.selectedFile.name,
						filePath: 'logos/',
						contentType: this.selectedFile.type,
						file: file.replace(/^data:.+;base64,/, '')
					});
			}
			if(this.state.myUser.pkIdUsuario) {
				console.log('goona update');
				const body = JSON.parse(JSON.stringify(values));
				delete body['rolName'];
				delete body['tableData'];
				delete body['permisos'];
				delete body['salarios'];

				if (postImgRes) {
					body.imgUrl = postImgRes.data.key;
					values.imgUrl = postImgRes.data.key;
				}
				if(this.selectedFirma){
					toBase64(this.selectedFirma)
					.then(file => {
						axios.post(Constants.api+'/v1/file-upload',
						{
							fileName: 'firma',
							filePath: `firmas/user/${body.pkIdUsuario}/`,
							contentType: this.selectedFirma.type,
							file: file.replace(/^data:.+;base64,/, '')
						})
						.then(res => {
							console.log('img updated');
						})
					})
				}
				axios.put(Constants.api+'/v1/usuario/' + body.pkIdUsuario,body)
				.then(async resp => {
					const responseSaveFiles = new Subject();
					const sub = responseSaveFiles.asObservable().subscribe(async (message) => {
						if (message) {
							sub.unsubscribe();
							if (window.location.href.indexOf("mi-usuario")<0) {
								this.setState({
									openWheel: false
								});
								this.props.openList();
							} else {
								this.setState({
									openWheel: false
								});
								window.location.href = '/';
							}
						}
					});
					saveAction.next([responseSaveFiles, null]);
				});
			} else {
				const body = JSON.parse(JSON.stringify(values));
				delete body['rolName'];
				delete body['tableData'];
				delete body['permisos'];
				delete body['salarios'];
				if (postImgRes) {
					body.imgUrl = postImgRes.data.key;
				}
				body.clave = body.usuario.split('@')[0];
				body.fkIdRol = this.state.myUser.fkIdRol;
				axios.post(Constants.api+'/v1/usuario',body)
				.then(async (resp)=>{
					body.pkIdUsuario = resp.data[0].insertId;
					let salariosToSave = [];
					if(this.selectedFirma){
						const file = toBase64(this.selectedFirma);
						salariosToSave.push(axios.post(Constants.api+'/v1/file-upload',
						{
							fileName: 'firma',
							filePath: `firmas/user/${body.pkIdUsuario}/`,
							contentType: this.selectedFirma.type,
							file: file.replace(/^data:.+;base64,/, '')
						}))
					}
					this.state.salarios.forEach(sal => {
						delete sal['fechaMap'];
						delete sal['tableData'];
						delete sal['pkIdSalario'];
						sal.fkIdUsuario = body.pkIdUsuario;
						salariosToSave.push(axios.post(Constants.api+'/v1/salario',sal))
					});
					Promise.all(salariosToSave)
					.then(res => {
						console.log('all requests done');
					})
					const responseSaveFiles = new Subject();
					const sub = responseSaveFiles.asObservable().subscribe(async (message) => {
						if (message) {
							this.setState({
								openWheel: false
							});
							sub.unsubscribe();
							this.props.openList();
						}
					});
					saveAction.next([responseSaveFiles, body]);
				});
			}
		} else {
			errorRol = "Campo requerido";
		}
	}

	toggleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	toggleAlertPass = () => {
		this.setState({isPassOpen: !this.state.isPassOpen});
	}

	changeRol = (val) => {
		let user = this.state.myUser;
		user.fkIdRol = val;
		this.setState({
			myUser: user
		});
	}

	changeVendedor = (val) => {
		let user = this.state.myUser;
		user.idVendedorActual = val;
		this.setState({
			myUser: user
		});
	}

	async componentDidMount() {
		axios.get(Constants.api + '/v1/usuario?type=vendedores')
		.then((resp)=>{		    	
			this.setState({
				vendedores: resp.data
			});
		}).catch(error => {
			if (error.message.indexOf('401') >= 0) {
				this.context.logOut();
			}
		});
		if (!this.props.rolList) {
			await axios.get(Constants.api + '/v1/rol?all=true')
		    .then((resp)=>{		    	
			  	this.setState({
			  		rolList: resp.data
			  	});
		    }).catch(error => {
		    	if (error.message.indexOf('401') >= 0) {
		    		this.context.logOut();
		    	}
            });
		} else {
			this.setState({
		  		rolList: this.props.rolList
		  	});
		}
		let user = this.props.selectedUser || {};
		if (window.location.href.indexOf("mi-usuario")>=0) {
			let resp = await axios.get(Constants.api + '/v1/usuario/'+JSON.parse(localStorage.getItem('izqToken')).usuarioId)	    	
			user = resp.data[0];
		}
		if(user) {
			let resp = await axios.get(Constants.api + '/v1/usuario/'+user.pkIdUsuario);
			user = resp.data[0];
			if (!user.correos || !user.correos.length) {
				user.correos = [{
					correo: '',
					fkIdUsuario: user.pkIdUsuario
				}];
			}
			if (!user.telefonos || !user.telefonos.length) {
				user.telefonos = [{
					telefono: '',
					fkIdUsuario: user.pkIdUsuario
				},{
					telefono: '',
					fkIdUsuario: user.pkIdUsuario
				}];
			}
		}
		this.setState({
			selectedUser: user,
			myUser: user
		});
	}

	seleccionarImagen(img){
		this.selectedFile = img;
	}

	seleccionarFirma(img){
		this.selectedFirma = img;
	}

	seleccionarArchivo(file,type){
		this.setState({
			[type]: file
		})
	}

	borrarArchivo(file, type){
		axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
						.then(succ => {
							this.setState({
								[type]:null
							})
						})
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

	updateOnSalarios = (sals) => {
		console.log(sals);
		this.setState({
			salarios: sals
		})
	}


    render() {
    	const map = (date) => {
			return date != null ? date.split('T')[0] : '';
		}
        const today = new Date().toISOString().split('T')[0];
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.myUser}
							validateOnChange={false}
							validationSchema={Yup.object().shape({
					          	nombre: Yup.string()
									.required('Campo requerido'),
								apellido1: Yup.string()
									.required('Campo requerido'),
								apellido2: Yup.string()
									.required('Campo requerido'),
								usuario: Yup.string()
									.required('Campo requerido'),
							  	correos: Yup.array().of(
									Yup.object().shape({
									  	correo: Yup.string()
									  		.required('Campo requerido')
									})
								),
								cedula: Yup.string()
									.required('Campo requerido'),
								fechaIngreso: Yup.string()
									.required('Campo requerido'),
								fechaNacimiento: Yup.string()
									.required('Campo requerido')
					      	})}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="true">
								{this.state.myUser.nombre && <h3>Editar Usuario:</h3>}
								{!this.state.myUser.nombre && <h3>Nuevo Usuario:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.nombre}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.apellido1)}`} name="apellido1" onChange={handleChange} value={values.apellido1} label="Primer Apellido" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.apellido1}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.apellido2)}`} name="apellido2" onChange={handleChange} value={values.apellido2} label="Segundo Apellido" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.apellido2}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.usuario)}`} name="usuario" onChange={handleChange} value={values.usuario} label="Usuario" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.usuario}</p>
								</div>
								<FieldArray
									name="correos"
									render={arrayHelpers => (
									<div>
										{values.correos.map((correo, index) => (
											<div key={index}>
												<div className="form-group">
													<TextField className={`input-form ${this.checkStyle(errors.correos)}`} name="correos[0].correo" onChange={handleChange} value={values.correos[0]?.correo} label="Correo" disabled={this.context.myUser.fkIdRol!==1}/>
													{errors.correos && errors.correos.length && <p className="invalid-feedback add-form">{errors.correos[0].correo}</p>}
												</div>
											</div>
										))}
									</div>
									)}
								/>
								{/* <div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.correos)}`} name="correos[0].correo" onChange={handleChange} value={values.correos[0]?.correo} label="Correo" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.correos}</p>
								</div> */}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.cedula)}`} name="cedula" onChange={handleChange} value={values.cedula} label="Cédula" disabled={this.context.myUser.fkIdRol!==1}/>
									<p className="invalid-feedback add-form">{errors.cedula}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.fechaNacimiento)}`} name="fechaNacimiento" onChange={handleChange} value={map(values.fechaNacimiento)} label="Fecha Nacimiento" disabled={this.context.myUser.fkIdRol!==1} 
										InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="date"
                                        inputProps={{ max: today }}/>
									<p className="invalid-feedback add-form">{errors.fechaNacimiento}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.fechaIngreso)}`} name="fechaIngreso" onChange={handleChange} value={map(values.fechaIngreso)} label="Fecha Ingreso" disabled={this.context.myUser.fkIdRol!==1} 
										InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="date"
                                        inputProps={{ max: today }}/>
									<p className="invalid-feedback add-form">{errors.fechaIngreso}</p>
								</div>
								<div className="form-group">
									<TextField type="number" className={`input-form ${this.checkStyle(errors.telefono1)}`} name="telefonos[0].telefono" onChange={handleChange} value={values.telefonos[0]?.telefono} label="Telefono Principal"/>
									<p className="invalid-feedback add-form">{errors.telefono1}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.telefono2)}`} name="telefonos[1].telefono" onChange={handleChange} value={values.telefonos[1]?.telefono} label="Telefono Secundario"/>
									<p className="invalid-feedback add-form">{errors.telefono2}</p>
								</div>
								<div className="form-group">
									<span>Rol</span>
									<select className={`input-form ${this.checkStyle(errors.fkIdRol)}`} value={values.fkIdRol} name="fkIdRol" onChange={(e) => this.changeRol(e.target.value)}>
										<option key="0" value="">Seleccione el Rol</option>
										{this.state.rolList.map((rol) => <option key={rol.pkIdRol} value={rol.pkIdRol}>{rol.nombre}</option>)}
									</select>
									<p className="invalid-feedback add-form">{errorRol}</p>
								</div>
								{values.fkIdRol == 2 && 
									<div className="form-group">
										<span>Vendedor</span>
										<select className={`input-form ${this.checkStyle(errors.idVendedorActual)}`} value={values.idVendedorActual} name="idVendedorActual" onChange={(e) => this.changeVendedor(e.target.value)}>
											<option key="0" value="">Seleccione el vendedor</option>
											{this.state.vendedores.map((v) => <option key={v.pkIdUsuario} value={v.pkIdUsuario}>{v.nombre}</option>)}
										</select>
										{/* <p className="invalid-feedback add-form">{errorRol}</p> */}
									</div>
								}
								<div className="form-group">
									{this.state.myUser.pkIdUsuario && <TextField className={`input-form ${this.checkStyle(errors.salario)}`} name="salarios[salarios.length-1].salario" value={values.salarios[values.salarios.length-1]?.salario} label="Salario actual" disabled/>}<br></br>
									<span onClick={()=>this.toggleAlert()} className="open-salarios">Administrar Salario</span>
								</div>
								<div className="actions">
									{window.location.href.indexOf("mi-usuario")<0 && <button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
									</button>}
									<button type="submit" id="save" disabled={isSubmitting} className="btn btn-save">
									Guardar
									</button>
								</div>
								<ErrorMessage name="user" component="div" className="invalid-feedback" />
								<ErrorMessage name="pass" component="div" className="invalid-feedback" />
								{this.state.hasError && <p className="invalid-feedback">Datos incorrectos</p>}
							</form>
						)}
						</Formik>
					</div>
					<div>
						<UserIcon seleccionarImagen={(e) => this.seleccionarImagen(e)} title="Imagen de usuario"> </UserIcon>
						{this.state.myUser.imgUrl && <div className="logo-display">
							<span>Logo actual:</span><br/>
							<img src={Constants.imgHost + this.state.myUser.imgUrl} width="200"></img>
						</div>}
						<UserIcon seleccionarImagen={(e) => this.seleccionarFirma(e)} title="Firma de usuario"> </UserIcon>
						<div className="logo-display">
							<span>Firma actual:</span><br/>
							<img src={Constants.imgHost + 'firmas/user/'+this.state.myUser.pkIdUsuario+'/firma'} width="200" alt="no tiene firma de momento"></img>
						</div>
						{this.state.myUser.pkIdUsuario && <p onClick={()=>this.toggleAlertPass()} className="open-link">Cambiar Contraseña</p>}
					</div>
					{this.state.myUser && <div>
						<Archivos user={this.state.myUser} saveAction={saveAction} token={this.state.token}></Archivos>
					</div>}
					<GenericAlert isOpen={this.state.isAlertOpen} data={{title:'Salarios'}} onClose={this.toggleAlert}>
						{this.state.myUser.pkIdUsuario && <Salarios usuario={this.state.myUser.pkIdUsuario} ></Salarios>}
						{!this.state.myUser.pkIdUsuario && <SalariosLocal usuario={this.state.myUser.pkIdUsuario} updateOnSalarios={this.updateOnSalarios}></SalariosLocal>}
					</GenericAlert>
					<GenericAlert isOpen={this.state.isPassOpen} data={{title:'Cambio de Contraseña'}} onClose={this.toggleAlertPass}>
						<Password usuario={this.state.myUser} onClose={this.toggleAlertPass}></Password>
					</GenericAlert>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarUsuarios.contextType = MainContext;
export default AgregarUsuarios;