import React, { Component } from 'react';
import { Formik, ErrorMessage} from 'formik';
import MainContext from '../MainContext';
import Constants from '../Constants';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';
import './Login.css';
import logo from './../assets/img/LOGO_IZQ.webp';

class Login extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      initial : 
	        {
	          user: localStorage.getItem('userName'),
	          pass: ''
	        },
	        hasError: false
	    }
	  }

	  onSubmit = (values, {setSubmitting, resetForm}) => {
	    setSubmitting(false);
	    this.setState({hasError: false});
	    let user = {
	      email: values.user,
	      password: values.pass
	    }
	   if(user.email && user.password) {
		    axios.post(Constants.api + '/v1/login',{
				"usuario": user.email,
				"clave": user.password
			},
			{'Content-Type': 'application/json'},{withCredentials: true})
		    .then((resp)=>{
		    	localStorage.setItem('userName', user.email);
		 		this.context.logIn(JSON.stringify(resp.data));
		    }).catch(error => {
		    	this.setState({hasError: true});
        		resetForm({
        			user: localStorage.getItem('userName'),
	          		pass: ''
	          	});
            });
		}
	  }

	  componentDidMount(){
	  }
    render() {
        return (
	            <div className="login-page">
	                <Formik
				        enableReinitialize
				        initialValues={this.state.initial}
				        validationSchema={Yup.object().shape({
		                    user: Yup.string()
		                        .required('Ingrese un Usuario'),
		                    pass: Yup.string()
		                        .required('Ingrese la contraseña')
		                })}
						onSubmit={this.onSubmit}
					>
					{({
					  values,
					  touched,
					  handleChange,
					  handleSubmit,
					  isSubmitting
					}) => (
						<form onSubmit={handleSubmit} validate="true">
							<h3>Ingrese sus datos</h3>
							<div className="form-group">
							  <TextField className="input-login" name="user" onChange={handleChange} value={values.user} label="Usuario" variant="outlined"/>
							</div>
							<div className="form-group">
							  <TextField className="input-login" type="password" name="pass" onChange={handleChange} value={values.pass} label="Contraseña" variant="outlined"/>
							</div>
							<button type="submit" disabled={isSubmitting} className="btn btn-success">
							  Entrar
							</button>
							<ErrorMessage name="user" component="div" className="invalid-feedback" />
							<ErrorMessage name="pass" component="div" className="invalid-feedback" />
							{this.state.hasError && <p className="invalid-feedback">Datos incorrectos</p>}
						</form>
					)}
					</Formik>
					<img className="logo-img" src={logo} />
	            </div>
        );
    }
}
Login.contextType = MainContext;
export default Login;