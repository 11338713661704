import React, { Component } from 'react';
import './AgregarAcabado.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UserIcon from '../../../Usuarios/UserIcon/UserIcon';
import Wheel from '../../../shared/dialog/wheel/Wheel';

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

class AgregarAcabado extends Component {
	versionesOriginal = [];

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        acabado: {
				nombre: '',
				precio: null,
				fkIdCategoria: '',
				imgUrl: '',
				versiones: [],
				ranking: ''
			},
			alertData: {
				title: "Error",
				message: "No se puede editar este acabado",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			categorias: [],
			openWheel: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

	moveArrayItemToNewIndex = (arr, old_index, new_index) => {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr; 
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
		let version = null;
		let toSubmit = JSON.parse(JSON.stringify(values));
		if(toSubmit['precio']){
			version = {
				precio: toSubmit['precio'],
				fechaAgregado: new Date().toISOString().split('T')[0]
			}
		}
		delete toSubmit['tableData'];
		delete toSubmit['versiones'];
		delete toSubmit['precio'];
		delete toSubmit['cat'];
		delete toSubmit['rankingCategoria'];
		delete toSubmit['fkIdCategoriaDistribucion'];
		let catArray = this.props.acabados.filter(cat => cat.fkIdCategoria === toSubmit.fkIdCategoria);
		delete toSubmit['categoria'];
		let oldRank = parseInt(this.state.acabado.ranking)-1;
		if (!this.state.acabado.pkIdAcabado) {
			catArray.push(toSubmit);
			oldRank = catArray.length-1;
		}
		if (values.ranking && parseInt(values.ranking)-1 < catArray.length) {
			catArray = this.moveArrayItemToNewIndex(catArray, oldRank, parseInt(values.ranking)-1);
		} else {
			//catArray.push(toSubmit);
			catArray = this.moveArrayItemToNewIndex(catArray, oldRank, catArray.length-1);
		}
		let newRanking = 0;
		for (let i = 0; i < catArray.length; i++) {
			if(catArray[i]) {
				delete catArray[i]['tableData'];
				delete catArray[i]['versiones'];
				delete catArray[i]['precio'];
				delete catArray[i]['categoria'];
				delete catArray[i]['cat'];
				delete catArray[i]['rankingCategoria'];
				delete catArray[i]['fkIdCategoriaDistribucion'];
				newRanking++;
				catArray[i].ranking = newRanking;
				axios.put(Constants.api+'/v1/acabado/' + catArray[i].pkIdAcabado,catArray[i]);
			}
		}
		if(this.state.acabado.pkIdAcabado && toSubmit.esEditable) {
			axios.put(Constants.api+'/v1/acabado/' + toSubmit.pkIdAcabado,toSubmit)
			.then(async resp => {
				let toUpdate = [];
				console.log('this.state.acabado',this.state.acabado);
				if (this.state.acabado.versiones) {
					this.state.acabado.versiones.forEach((ver,index) => {
						if(ver.precio != this.versionesOriginal[index].precio){
							this.versionesOriginal[index].precio = ver.precio;
							toUpdate.push(axios.put(Constants.api+'/v1/version_acabado/'+this.versionesOriginal[index].pkIdVersionAcabado,this.versionesOriginal[index]))
						}
					});
				}
				if(version){
					version.fkIdAcabado = toSubmit.pkIdAcabado;
					toUpdate.push(axios.post(Constants.api+'/v1/version_acabado',version));
				}
				if(toUpdate.length > 0){
					Promise.all(toUpdate)
					.then(res => {
						this.setState({
							openWheel: false
						});
						this.props.togShowList();
					});
				} else {
					this.setState({
						openWheel: false
					});
					this.props.togShowList();
				}
			});
		} else if(this.state.acabado.pkIdAcabado && !toSubmit.esEditable) {
			this.toogleAlert();
		} else{
			axios.post(Constants.api+'/v1/acabado',toSubmit)
			.then(async (resp) => {
				version.fkIdAcabado = resp.data[0].insertId;
				axios.post(Constants.api+'/v1/version_acabado',version)
				.then(async (respVer) => {
					this.setState({
						openWheel: false
					});
					this.props.togShowList();
				});
			});
		}
	}

	agregarVersion() {
		this.setState({
			openWheel: true
		});
		let ver = 1;
		if(this.state.versiones.length > 0)
			ver = Number(this.state.versiones[this.state.versiones.length - 1].nombre) + 1;
		const nuevaVersion = {
			nombre: ver,
			fechaAgregado: new Date().toISOString().split('T')[0]
		};
		axios.post(Constants.api + '/v1/version_acabado',
		nuevaVersion).then(res => {
			axios.get(Constants.api + '/v1/version_acabado')
			.then(resp => {
				this.setState({
					openWheel: false,
					versiones: resp.data
				});
			});
		})
	}

	componentDidMount() {
		axios.get(Constants.api + '/v1/categoria_acabado')
		.then((resp)=>{
			if(this.props.selectedItem){
				this.versionesOriginal = JSON.parse(JSON.stringify(this.props.selectedItem.versiones));
				this.setState({ 
					categorias: resp.data,
					acabado: this.props.selectedItem
				});
			} else {
				this.setState({ 
					categorias: resp.data
				});
			}
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
	}

	handleChangeAcabado(e){
		let acabado = this.state.acabado;
		acabado.versiones[e.target.name].precio = e.target.value;
		this.setState({
			acabado: acabado
		});
	}

	handleChangeCat(e){
		let acabado = this.state.acabado;
		acabado.fkIdCategoria = e.target.value;
		acabado.ranking = this.props.acabados.filter(cat => cat.fkIdCategoria === e.target.value).length+1;
		this.setState({
			acabado: acabado
		});
	}

	setImg(e){
		let acabado = this.state.acabado;
		acabado.imgUrl = e.target.value;
		this.setState({
			acabado: acabado
		});
	}

	async seleccionarImagen(img){
		if(this.state.acabado.imgUrl.indexOf(img.name) === -1){
			const file = await toBase64(img);
			const postImgRes = await axios.post(Constants.api+'/v1/file-upload',
				{
					fileName: img.name,
					filePath: 'logos/',
					contentType: img.type,
					file: file.replace(/^data:.+;base64,/, '')
				});
			this.setState({
				acabado: {
					...this.state.acabado,
					imgUrl: Constants.imgHost + postImgRes.data.Key
				}
			});
		}
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.acabado}
							validateOnChange={false}
							validationSchema={Yup.object().shape({
						        nombre: Yup.string()
									.required('Ingrese el Nombre'),
								fkIdCategoria: Yup.string()
								.required('Ingrese la categoria'),
								precio: Yup.number().nullable(true),
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false" className="acabados">
								{this.state.acabado.nombre && <h3>Editar acabado:</h3>}
								{!this.state.acabado.nombre && <h3>Nuevo acabado:</h3>}
								<div className="form-group">
									<FormControl className="categoria">
										<InputLabel id="demo-simple-select-label">Categoria</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											className={`input-form ${this.checkStyle(errors.fkIdCategoria)}`}
											name="fkIdCategoria"
											value={values.fkIdCategoria}
											onChange={(e) => this.handleChangeCat(e)}
											>
												<MenuItem value=''>Seleccionar</MenuItem>
											{this.state.categorias.map(cat => {
												return <MenuItem value={cat.pkIdCategoriaAcabado}>{cat.nombre}</MenuItem>
											})}
										</Select>
									</FormControl>
									<p className="invalid-feedback add-form">{errors.fkIdCategoria}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre"/>
								</div>
                                <p className="invalid-feedback add-form">{errors.nombre}</p>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.ranking)}`} name="ranking" onChange={handleChange} value={values.ranking} label="Ranking de Categoría"/>
								</div>
								<div className="form-group">
									{this.state.acabado.versiones && this.state.acabado.versiones.map((version, index) => {
										return (
											<div>
												<TextField className="input-form" value={version.precio} name={index} onChange={(e) => this.handleChangeAcabado(e)} label={'Precio agregado: ' + version.fechaAgregado?.split('T')[0]}/>
											</div>
										);
									})}
									<div className="precio">
										<div className="form-group">
											<TextField className={`input-form ${this.checkStyle(errors.precio)}`} name="precio" onChange={handleChange} value={values.precio} label="Nuevo precio"/>
										</div>
										<p className="invalid-feedback add-form">{errors.precio}</p>
									</div>
									{/* <span onClick={(e)=> this.agregarVersion()}>Agregar nueva versión</span> */}
								</div>
								<div className="form-group">
									<div className="form-group">
										<TextField className={`input-form ${this.checkStyle(errors.imgUrl)}`} name="imgUrl" onChange={(e)=>{handleChange(e); this.setImg(e)}} value={values.imgUrl} label="Link de la imagen"/>
									</div>
									<p className="invalid-feedback">{errors.imgUrl}</p>
								</div>
								<div className="form-group">
									<UserIcon seleccionarImagen={(e) => this.seleccionarImagen(e)} title="Imagen del acabado" notThumbs={true}> </UserIcon>
									{/* {this.state.myUser.imgUrl && <div className="logo-display">
										<span>Logo actual:</span><br/>
										<img src={Constants.imgHost + this.state.myUser.imgUrl} width="200"></img>
									</div>} */}
								</div>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<div>
						<img src={this.state.acabado.imgUrl} />
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarAcabado.contextType = MainContext;
export default AgregarAcabado;