import React, { Component } from 'react';
import './AgregarPromociones.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Wheel from '../../../shared/dialog/wheel/Wheel';

class AgregarPromociones extends Component {

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        categoria: {
				descripcion: '',
				promocion: '',
				fechaInicio: '',
				fechaFin: ''
			},
			alertData: {
				title: "Error",
				message: "hubo un error",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			openWheel: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
        delete values['tableData'];
        console.log(this.state.categoria)
		if(this.state.categoria.pkIdPromocion >= 0) {
			axios.put(Constants.api+'/v1/promociones/' + values.pkIdPromocion,values)
			.then(async resp => {
				this.setState({
					openWheel: false
				});
                this.props.togShowList();
			});
		} else {
			axios.post(Constants.api+'/v1/promociones',values)
			.then(async (resp) => {
				this.setState({
					openWheel: false,
					categoria: {
                        descripcion: values.descripcion,
						pkIdPromocion: resp.data[0].insertId
					}
				});
                this.props.togShowList();
			});
		}
	}

	componentDidMount() {
		this.setState({
			categoria: this.props.selectedItem
		});
	}

    render() {
    	const map = (date) => {
			return date != null ? date.split('T')[0] : '';
		}
        const today = new Date().toISOString().split('T')[0];
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.categoria}
							validationSchema={Yup.object().shape({
						        descripcion: Yup.string()
						            .required('Ingrese la Descripción'),
						        promocion: Yup.string()
						            .required('Ingrese la Promoción'),
						        fechaInicio: Yup.string()
						            .required('Ingrese una fecha'),
						        fechaFin: Yup.string()
						            .required('Ingrese una fecha')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								<h3>Editar Promoción:</h3>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.descripcion)}`} name="descripcion" onChange={handleChange} value={values.descripcion} label="Descripción"/>
									<p className="invalid-feedback add-form">{errors.descripcion}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.promocion)}`} name="promocion" onChange={handleChange} value={values.promocion} step=".01" type="number" label="Porcentaje"/>
									<p className="invalid-feedback add-form">{errors.promocion}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.fechaInicio)}`} name="fechaInicio" onChange={handleChange} value={map(values.fechaInicio)} label="Fecha Inicio" 
										InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="date"/>
									<p className="invalid-feedback add-form">{errors.fechaInicio}</p>
								</div>
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.fechaFin)}`} name="fechaFin" onChange={handleChange} value={map(values.fechaFin)} label="Fecha Fin" 
										InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="date"/>
									<p className="invalid-feedback add-form">{errors.fechaFin}</p>
								</div>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarPromociones.contextType = MainContext;
export default AgregarPromociones;