import React, {useState, useEffect} from 'react';
import './Step9.css';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import Oferta from '../Step8/Oferta/Oferta';
import axios from 'axios';



let docsMarked = [];


function Step9(props) {
    const [openWheel, setWheel] = useState(false);
    const [ofertaFinal, setOfertaFinal] = useState(null);
    const [ofertaFinalFiles, setOfertaFinalFiles] = useState([]);
   
    const enviarACliente = async () => {
        setWheel(true);
        const o = ofertaFinal;
        let respUser = await axios.get(Constants.api+'/v1/usuario/'+props.miProyecto.fkIdUsuario);
        axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/ofertas/${o.pkIdOferta}/`)
        .then(res => {
            if (res.data.Contents) {
                setOfertaFinalFiles(res.data.Contents);
            }
            let files = ofertaFinalFiles;
            let attachments = [];
            files.forEach(e => {
                if(docsMarked.some(d => e.Key.indexOf(d) > -1)){
                    attachments.push({
                        type: 'S3',
                        path: e.Key
                    });
                }
            });
            axios.get(Constants.api + '/v1/usuario/'+props.miProyecto.fkIdVendedor)
            .then(vend => {
                let email = {
                    emailFiles: attachments,
                    emailBody:{
                        from: vend.data[0].correos[0].correo,
                        to: [respUser.data[0].correos[0].correo],
                        subject: 'Generamos una oferta para usted.',
                        body: 'Generamos una oferta para usted.',
                        type: 'template',
                        name: 'listo-para-cliente',
                        mappings: {
                            nombrecomprador: respUser.data[0].nombre + ' ' + respUser.data[0].apellido1 + ' ' + respUser.data[0].apellido2,
                            imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                        }
                    }
                }
                axios.post(Constants.api+'/v1/send_mail/', email)
                .then(res => {
                    setWheel(false);
                    alert('Se ha enviado esta oferta al cliente!');
                    console.log('email sent');
                });
            });
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            delete proyData['comprador'];
            delete proyData['vendedor'];
            delete proyData['estimador'];
            proyData.fkIdUsuarioEstimador = JSON.parse(localStorage.getItem('izqToken')).usuarioId;
            axios.put(Constants.api+`/v1/proyecto/${proyData.pkIdProyecto}`,proyData)
            .then(res => {
                axios.get(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto)
                .then(body => {
                    props.setProyecto(body.data[0]);
                    // setWheel(false);
                })
            });
            let of = JSON.parse(JSON.stringify(o));
            of.estado = 4;//enviada a cliente
            axios.put(Constants.api+`/v1/oferta/${of.pkIdOferta}`,of)
            .then(res => {
                o.estado = 4;
                setOfertaFinal(o);
            });
        });
    }

    useEffect(() => {
        axios.get(Constants.api+`/v1/oferta?proyecto=${props.miProyecto.pkIdProyecto}`)
        .then(res => {
            if (res.data.length > 0) {
                const o = res.data.pop();
                setOfertaFinal(o);
                axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/ofertas/${o.pkIdOferta}/`)
                .then(res => {
                    if (res.data.Contents) {
                        setOfertaFinalFiles(res.data.Contents);
                    }
                })
            }
        });
        docsMarked = [];
    },[]);

    const handleChangeCheck = (e,ad) => {
        console.log(ad);
        if(e.target.checked){
            docsMarked.push(ad);
        } else {
            docsMarked = docsMarked.filter(d => d != ad);
        }
        console.log(docsMarked);
    }

    return (
        <div>
            <div className="form-container">
                <div className="cont">
                    <div>
                        Coordinación de reunión de proyecto
                    </div>
                    {ofertaFinal?.estado == 4 && <span>Esta oferta ya fue enviada al cliente</span>}
                    {/* <p>
                        La oferta final aprobada por gerencia fue por {ofertaFinal?.oferta}
                    </p>
                    <div>
                        Documentos de la oferta
                        <ul>
                            {ofertaFinalFiles && ofertaFinalFiles.map(ad => {
                                return (
                                    <li>
                                        <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank">{ad.Key.split('/').pop()}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div> */}
                    <div>
                        {ofertaFinal != null && <Oferta o={ofertaFinal} miProyecto={props.miProyecto} markDoc={(e,name)=> handleChangeCheck(e,name)}></Oferta>}
                    </div>
                </div>
            </div>
            <div className="form-container">
                <div>
                    <button className="btn btn-cancel" onClick={props.prevStep}>
                        Atrás
                    </button>
                    <button type="submit" className="btn btn-save" onClick={enviarACliente}>
                        Enviar oferta a cliente.
                    </button>
                </div>
            </div>
            {openWheel && <Wheel/>}
        </div>
    );
}

export default Step9;