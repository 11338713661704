import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Formik, FieldArray} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import './Step1.css';

function Step1(props) {

    const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
    }

    let proyData = {};  
    const [openWheel, setWheel] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    let { id } = useParams();
    const [userData, setUserData] = useState({
        apellido1: '',
        apellido2: '',
        cedula: '',
        clave: '',
        fkIdRol: '2',
        ingresoFamiliar: '0',
        nombre: '',
        ocupacion: '',
        fechaNacimiento: '',
        fechaIngreso: '',
        ultimoLogin: null,
        usuario: '',
        correos: [{
                correo: ''
            }],
        telefonos: [{
                telefono: ''
            }],
        salarios:[{
            salario: ''
        }]
    });

    const onSubmit = async (values, {setSubmitting, resetForm}) => {
        console.log(isCreating);
        if(!isCreating){
            setIsCreating(true);
            const body = JSON.parse(JSON.stringify(values));
            setUserData(JSON.parse(JSON.stringify(values)));
            props.setUserData(JSON.parse(JSON.stringify(values)));

            delete body['rolName'];
            delete body['tableData'];
            delete body['permisos'];
            delete body['salarios'];
            if (id || props.miProyecto.pkIdProyecto) {
                console.log('goona update', props.miProyecto);
                setWheel(true);

                await axios.put(Constants.api+'/v1/usuario/' + body.pkIdUsuario,body)
                .then(async resp => {
                    proyData.pkIdProyecto = id || props.miProyecto.pkIdProyecto;
                    props.setProyecto(props.miProyecto)
                    props.nextStep();
                });

                setIsCreating(false);
            } else {
                let val = await axios.get(Constants.api + '/v1/usuario?validate=' + values.correos[0].correo);
                console.log(val);
                let conf = true
                if(val.data.length > 0){
                    let cli = val.data[val.data.length - 1];
                    if(cli.pkIdUsuario !== JSON.parse(localStorage.getItem('izqToken')).usuarioId){
                        conf = false;
                        alert(`Este cliente ya existe en el sistema y fue creado por ${cli.nombre} ${cli.apellido1} ${cli.apellido2}, solicite a un administrador que actualice el cliente.`);
                    }
                }
                if(conf){
                    body.idVendedorActual = JSON.parse(localStorage.getItem('izqToken')).usuarioId;
                    body.fechaIngreso = formatDate(new Date()); 
                    await axios.post(Constants.api+'/v1/usuario',body)
                    .then(async (resp)=>{
                        proyData.fkIdUsuario = resp.data[0].insertId;
                        proyData.fkIdVendedor = JSON.parse(localStorage.getItem('izqToken')).usuarioId;
                        proyData.currentStep = 2;
                        console.log(proyData);
                        axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdVendedor)
                        .then(vend => {
                            let email = {
                                emailFiles: [],
                                emailBody:{
                                    from: vend.data[0].correos[0].correo,
                                    to: [values.correos[0].correo],
                                    subject: 'Bienvenido',
                                    body: 'Bienvenido a la contructora izquierdo',
                                    type: 'template',
                                    name: 'welcome',
                                    mappings: {
                                        nombre: values.nombre,
                                        vendedor: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                        imgprimera: `<img src="${Constants.imgHost}assets/email/welcome1.jpeg" alt="bienvenido" width="400"/>`,
                                        imgsegunda: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                                    }
                                }
                            }
                            axios.post(Constants.api+'/v1/send_mail/', email)
                            .then(res => {console.log('email sent')});
                        });

                        await axios.post(Constants.api+'/v1/proyecto',proyData)
                        .then(async (resp2)=>{
                            proyData.pkIdProyecto = resp2.data[0].insertId;
                            props.setProyecto(proyData);
                            setWheel(false);
                            props.nextStep();
                        });
                        setIsCreating(false);
                    });
                }
            }
        }
    }

    const checkStyle = (value) => {
		return value ? "error-input" : '';
    }
    
    useEffect(() => {
        console.log('proy', props.miProyecto);
        proyData = props.miProyecto;
        const myId = id || proyData.pkIdProyecto;
        if (myId ) {
            loadData(myId);
        }
    }, []);

    
	const changeIngreso = (val, values) => {
        let user = JSON.parse(JSON.stringify(values));
		user.ingresoFamiliar = val;
		setUserData(user);
	}

    const loadData = async(id) => {
        setWheel(true);
		let resp = await axios.get(Constants.api+'/v1/proyecto/'+id);    	
        let proy = resp.data[0];
        props.setProyecto(proy);
        proyData = resp.data[0];
        let respUser = await axios.get(Constants.api+'/v1/usuario/'+proy.fkIdUsuario);   	
        setUserData(respUser.data[0]);
        props.setUserData(respUser.data[0]);
        setWheel(false);
        if (proy.currentStep > 1) {
            if (proy.currentStep > 4) {
                await axios.get(Constants.api+'/v1/version_acabado_proyecto?proyecto='+proy.pkIdProyecto)
                .then((respDP)=>{
                    props.setAcabados(respDP.data.filter((dist) => dist.fkIdProyecto == resp.data[0].pkIdProyecto));
                });
            }
            props.setCurrentStep(proy.currentStep);
        }
    }

    return (
        <div className="form-container">
            <div>
                <h3>Datos del cliente:</h3>
                <Formik
                        enableReinitialize
                        initialValues={userData}
                        validateOnChange={false}
                        validationSchema={Yup.object().shape({
                            nombre: Yup.string()
                                .required('Campo requerido'),
                            correos: Yup.array().of(
                                Yup.object().shape({
                                    correo: Yup.string()
                                        .required('Campo requerido')
                                })
                            ),
                            ocupacion: Yup.string()
                                .required('Campo requerido'),
                            telefonos: Yup.array().of(
                                Yup.object().shape({
                                    telefono: Yup.string()
                                        .required('Campo requerido')
                                })
                            )
                        })}
                        onSubmit={onSubmit}
                    >
                    {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} validate="true">
                            <div className="form-group">
                                <TextField className={`input-form ${checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre Completo"/>
                                <p className="invalid-feedback add-form">{errors.nombre}</p>
                            </div>
                            <FieldArray
                                name="telefonos"
                                render={arrayHelpers => (
                                <div>
                                    {values.telefonos.map((telefono, index) => (
                                        <div key={index}>
                                            <div className="form-group">
                                            <TextField className={`input-form ${checkStyle(errors.telefono)}`} name="telefonos[0].telefono" onChange={handleChange} value={values.telefonos[0]?.telefono} label="Teléfono"/>
                                            {errors.telefonos && errors.telefonos.length && <p className="invalid-feedback add-form">{errors.telefonos[0].telefono}</p>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                )}
                            />
                            <FieldArray
                                name="correos"
                                render={arrayHelpers => (
                                <div>
                                    {values.correos.map((correo, index) => (
                                        <div key={index}>
                                            <div className="form-group">
                                                <TextField className={`input-form ${checkStyle(errors.correos)}`} name="correos[0].correo" onChange={handleChange} value={values.correos[0]?.correo} label="Correo"/>
                                                {errors.correos && errors.correos.length && <p className="invalid-feedback add-form">{errors.correos[0].correo}</p>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                )}
                            />
                            <div className="form-group">
                                <TextField className={`input-form ${checkStyle(errors.ocupacion)}`} name="ocupacion" onChange={handleChange} value={values.ocupacion} label="Profesión u Ocupación"/>
                                <p className="invalid-feedback add-form">{errors.ocupacion}</p>
                            </div>
                            <div className="form-group">
                                <select className={`input-form ${checkStyle(errors.ingresoFamiliar)}`} value={values.ingresoFamiliar} name="ingresoFamiliar" onChange={handleChange}>
                                    <option key="0" value="">Ingreso Familiar</option>
                                    <option key="1" value="1">500,000 - 1,000,000 colones</option>
                                    <option key="2" value="2">1,000,000 - 2,000,000 colones</option>
                                    <option key="3" value="3">+ 2,000,000 colones</option>
                                </select>
                            </div>
                            <div className="form-group buttons">
                                <button type="submit" disabled={isSubmitting || isCreating} className="btn btn-save">
                                Siguiente
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
			{openWheel && <Wheel/>}
        </div>
    );
}

export default Step1;