import React, { Component } from 'react';
import './AgregarDistribucion.css';
import axios from 'axios';
import { Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainContext from '../../../MainContext';
import Alert from '../../../shared/dialog/alert/Alert';
import Constants from '../../../Constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Wheel from '../../../shared/dialog/wheel/Wheel';

class AgregarDistribucion extends Component {

	constructor(props) {
        super(props);
	    this.state = {
	        hasError: false,
	        distribucion: {
				nombre: '',
				fkIdCategoria: ''
			},
			alertData: {
				title: "Error",
				message: "Esta distribución no se puede editar",
				cancel: "",
				accept: "Aceptar"
			},
			isAlertOpen: false,
			categorias: [],
			openWheel: false
	    }
	}

	toogleAlert = () => {
		this.setState({isAlertOpen: !this.state.isAlertOpen});
	}

	checkStyle = (value) => {
		return value ? "error-input" : '';
	}

  	onSubmit = async (values, {setSubmitting, resetForm}) => {
		this.setState({
			openWheel: true
		});
		delete values['tableData'];
		delete values['cat'];
		delete values['categoria'];
		if(this.state.distribucion.pkIdDistribucion && values.esEditable) {
			axios.put(Constants.api+'/v1/distribucion/' + values.pkIdDistribucion,values)
			.then(async resp => {
				this.setState({
					openWheel: false
				});
                this.props.togShowList();
			});
		} else if(this.state.distribucion.pkIdDistribucion && !values.esEditable) {
			this.setState({
				openWheel: false
			});
			this.toogleAlert();
		} else {
			axios.post(Constants.api+'/v1/distribucion',values)
			.then(async (resp) => {
				this.setState({
					openWheel: false
				});
				this.setState({
					distribucion: {
                        nombre: values.nombre,
						pkIdDistribucion: resp.data[0].insertId
					}
				});
                this.props.togShowList();
			});
		}
	}

	componentDidMount() {
		axios.get(Constants.api + '/v1/categoria_distribucion')
		.then((resp)=>{
		  	this.setState({
				distribucion: this.props.selectedItem,
				categorias: resp.data,
		  	});
	    }).catch(error => {
	    	if (error.message.indexOf('401') >= 0) {
	    		this.context.logOut();
	    	}
        });
	}

    render() {
        return (
            <div className="form-container">
				<div>
					<Formik
							enableReinitialize
							initialValues={this.state.distribucion}
							validationSchema={Yup.object().shape({
						        nombre: Yup.string()
									.required('Ingrese el Nombre'),
								fkIdCategoria: Yup.string()
						            .required('Ingrese la categoría')
						    })}
							onSubmit={this.onSubmit}
						>
						{({
						values,
						touched,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting
						}) => (
							<form onSubmit={handleSubmit} validate="false">
								{this.state.distribucion.nombre && <h3>Editar distribución:</h3>}
								{!this.state.distribucion.nombre && <h3>Nueva distribución:</h3>}
								<div className="form-group">
									<TextField className={`input-form ${this.checkStyle(errors.nombre)}`} name="nombre" onChange={handleChange} value={values.nombre} label="Nombre"/>
								</div>
                                <p className="invalid-feedback">{errors.nombre}</p>
								<div className="form-group">
									<FormControl className="categoria">
										<InputLabel id="demo-simple-select-label">Categoria</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											className={`input-form ${this.checkStyle(errors.fkIdCategoria)}`}
											name="fkIdCategoria"
											value={values.fkIdCategoria}
											onChange={handleChange}
											>
												<MenuItem value=''>Seleccionar</MenuItem>
											{this.state.categorias.map(cat => {
												return <MenuItem value={cat.pkIdCategoria}>{cat.nombre}</MenuItem>
											})}
										</Select>
                                		<p className="invalid-feedback">{errors.fkIdCategoria}</p>
									</FormControl>
								</div>
								<button disabled={isSubmitting} className="btn btn-cancel" onClick={this.props.togShowList}>
									Cancelar
								</button>
								<button type="submit" disabled={isSubmitting} className="btn btn-save">
									Guardar
								</button>
							</form>
						)}
						</Formik>
					</div>
					<Alert isOpen={this.state.isAlertOpen} data={this.state.alertData} onClose={this.toogleAlert} onAccept={this.toogleAlert}/>
					{this.state.openWheel && <Wheel/>}
            </div>
        );
    }
}

AgregarDistribucion.contextType = MainContext;
export default AgregarDistribucion;