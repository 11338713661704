import React, {useState, useEffect} from 'react';

import UsuarioArchivos from '../../../../Usuarios/AgregarUsuarios/Archivos/UsuarioArchivos/UsuarioArchivos';
import Constants from '../../../../Constants';
import Wheel from '../../../../shared/dialog/wheel/Wheel';
import axios from 'axios';
const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});



let docsOfertaToAdd = [];

function Oferta(props) {

    let [newDocsOferta, setNewDocsOferta] = useState([]);
    let [docsOferta, setDocsOferta] = useState([]);
    let [oferta, setOferta] = useState(null);

    useEffect(()=>{
        
        console.log(props.o);
        setOferta(props.o);
        // if(props.o.pkIdOferta) {
        docsOfertaToAdd = [];
        loadFiles();
        let d = document.getElementById('oferta');
        if(d){
            document.getElementById('oferta').value = props.o.oferta;
        }
        // }
    },[props])

    const seleccionarArchivo = (file, type) => {
        console.log('selectFile', type);
        setNewDocsOferta([...newDocsOferta, file]);
        docsOfertaToAdd = [...newDocsOferta, file];
    }

    const borrarArchivo = file => {
        axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
        .then(succ => {
            loadFiles();
        })
    }

    const borrarArchivoLocal = file => {
        let after = newDocsOferta.filter(function( obj ) {
            return obj.name !== file.name;
        });
        setNewDocsOferta(after);
    } 

    const loadFiles = () => {
        axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/ofertas/${props.o.pkIdOferta}/`)
        .then(res => {
            setDocsOferta(res.data.Contents);
        });
    }

    const crearOferta = () => {
        const ofValue = document.getElementById('oferta').value;
        const of = {
            fkIdProyecto: props.miProyecto.pkIdProyecto,
            oferta: ofValue,
            fechaAgregado: new Date(),
            estado: 1,
            aprobadaGerencia: 1,
            aprobadaCliente: 0
        };
        axios.post(Constants.api+`/v1/oferta`,of)
        .then(res => {
            of.pkIdOferta = res.data[0].insertId;
            guardaArchivos(of);
            props.reloadOfertas();
        });
    }

    const actualizarOferta = () => {
        let of = JSON.parse(JSON.stringify(props.o));
        if(document.getElementById('oferta')){
            const ofValue = document.getElementById('oferta').value;
            of.oferta = ofValue;
        }
        axios.put(Constants.api+`/v1/oferta/${props.o.pkIdOferta}`,of)
        .then(res => {
            guardaArchivos();
        });
    }

    const guardaArchivos = async (o) => {
        const oferta = o || props.o;
        if(docsOfertaToAdd.length > 0) {
			let filesToUpload = [];
            for(let i = 0; i < docsOfertaToAdd.length; i++){
                const file = docsOfertaToAdd[i];
                const fileData = await toBase64(file);
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/proyecto/${props.miProyecto.pkIdProyecto}/ofertas/${oferta.pkIdOferta}/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }))
            }
            const res = await Promise.all(filesToUpload);
        }
        setNewDocsOferta([]);
        docsOfertaToAdd = [];
        if(document.getElementById('oferta')){
            props.reloadOfertas();
        } else {
            loadFiles();
        }
    }

    const formatDate = (d) => {
        return new Date(d).toISOString().split('T')[0];
    }

    const handleChangeCheck = (e,ad) => {
        // ad.send = e.target.checked;
        props.markDoc(e,ad);
        // console.log(ad);
        // if(e.target.checked){
        //     docsMarked.push(ad);
        // } else {
        //     docsMarked = docsMarked.filter(d => d != ad);
        // }
        // console.log(docsMarked);
    }

    return (
        <div>
             
            {(oferta?.estado != '3' && oferta?.estado != '4') && <span><span>Precio total de la oferta:</span> <input type="number" id="oferta"></input></span>}
            {(oferta?.estado == '3' || oferta?.estado == '4') && <span>Precio total de la oferta aprobada: {oferta.oferta}</span>}
            <br/>
            {props.o.fechaAgregado && <span>Fecha agregada: {formatDate(props.o.fechaAgregado)}</span>}
            <br/>
            <UsuarioArchivos type="" seleccionarArchivo={(e) => seleccionarArchivo(e)}> </UsuarioArchivos>
            {newDocsOferta.length > 0 && <div>
                Archivos a agregar:
                <ul>
                    {newDocsOferta.map(ad => {
                        return <li><input type="checkbox" onChange={e => handleChangeCheck(e, ad.name)}/><span key={ad.name}>{ad.name}</span> <span onClick={(e) => {borrarArchivoLocal(ad)}} className="delete-file">Borrar</span></li>
                    })}
                </ul>
            </div>}
            {docsOferta.length > 0 && <div>
                Documentos asignados a la oferta:
                <ul>
                    {docsOferta.map(ad => {
                        return (
                            <li>
                                <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank"><input type="checkbox" onChange={e => handleChangeCheck(e, ad.Key.split('/').pop())}/>{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                            </li>
                        )
                    })}
                </ul>
            </div>}
            <br/>
            <br/>
            {props.o.pkIdOferta && <button onClick={actualizarOferta}>Actualizar Oferta</button>}
            {!props.o.pkIdOferta && <button onClick={crearOferta}>Crear Oferta</button>}
        </div>
    );
}

export default Oferta;