import React, {useState, useEffect} from 'react';
import './Step6.css';
import UsuarioArchivos from '../../../Usuarios/AgregarUsuarios/Archivos/UsuarioArchivos/UsuarioArchivos';
import Constants from '../../../Constants';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import axios from 'axios';

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});



let docsVendedorToAdd = [];
let docsMarked = [];

function Step6(props) {
    const [openWheel, setWheel] = useState(false);
    let [newDocsVendedor, setNewDocsVendedor] = useState([]);
    let [docsVendedor, setDocsVendedor] = useState([]);
    let [disenadores, setDisenadores] = useState([]);
    let [disenadorSelected, setDisenadorSelected] = useState(null);

    const seleccionarArchivo = (file, type) => {
        console.log('selectFile', type);
        if(type === 'vendedor'){
            setNewDocsVendedor([...newDocsVendedor, file]);
            docsVendedorToAdd = [...newDocsVendedor, file];
        }
    }

    const borrarArchivo = file => {
        axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
        .then(succ => {
            loadFiles();
        })
    }

    const borrarArchivoLocal = file => {
        let after = newDocsVendedor.filter(function( obj ) {
            return obj.name !== file.name;
        });
        setNewDocsVendedor(after);
    } 

    const loadFiles = () => {
        axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/vendedor/`)
        .then(res => {
            setDocsVendedor(res.data.Contents);
        });
    }

    const cargaDisenadores = () => {
        axios.get(Constants.api+`/v1/usuario?permiso=14`)
        .then(res => {
            console.log(res);
            setDisenadores(res.data);
        });
    }

    useEffect(() => {
        loadFiles();
        cargaDisenadores();
        let textArea = document.getElementsByClassName('notas')[0];
        textArea.value = props.miProyecto.notas;
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        setDisenadorSelected(proyData.fkIdDisenador)
        docsMarked = [];
    },[]);

    const guardaArchivos = async () => {
		if(docsVendedorToAdd.length > 0) {
			let filesToUpload = [];
            for(let i = 0; i < docsVendedorToAdd.length; i++){
                const file = docsVendedorToAdd[i];
                const fileData = await toBase64(file);
                filesToUpload.push(axios.post(Constants.api+'/v1/file-upload',
                {
                    fileName: file.name,
                    filePath: `files/proyecto/${props.miProyecto.pkIdProyecto}/vendedor/`,
                    contentType: file.type,
                    file: fileData.replace(/^data:.+;base64,/, '')
                }))
            }
            const res = await Promise.all(filesToUpload);
        }
        setNewDocsVendedor([]);
        docsVendedorToAdd = [];
        loadFiles();
    }


    const enviarDisenador = async () => {
        if(disenadorSelected){
            setWheel(true);
            console.log()
            if(docsVendedorToAdd.length > 0) await guardaArchivos();
            let textArea = document.getElementsByClassName('notas')[0];
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            delete proyData['comprador'];
            delete proyData['vendedor'];
            delete proyData['estimador'];
            axios.get(Constants.api+`/v1/file-upload?path=files/proyecto/${props.miProyecto.pkIdProyecto}/vendedor/`)
            .then(res => {
                let files = res.data.Contents;
                let attachments = [];
                files.forEach(e => {
                    console.log(e.Key);
                    console.log(docsMarked);
                    if(docsMarked.some(d => e.Key.indexOf(d) > -1)){
                        attachments.push({
                            type: 'S3',
                            path: e.Key
                        });
                    }
                });
                //enviar correo a diseñador
                axios.get(Constants.api + '/v1/usuario/'+proyData.fkIdVendedor)
                            .then(vend => {
                                const disenador = disenadores.find(d => d.pkIdUsuario ===  Number(disenadorSelected));
                                let email = {
                                    emailFiles: attachments,
                                    emailBody:{
                                        from: vend.data[0].correos[0].correo,
                                        to: [disenador.correo],
                                        subject: 'Proyecto listo para diseño',
                                        body: 'Proyecto listo para diseño',
                                        type: 'template',
                                        name: 'listo-para-diseno',
                                        mappings: {
                                            nombredisenador: `${disenador.nombre} ${disenador.apellido1} ${disenador.apellido2}`,
                                            nombrevendedor: vend.data[0].nombre + ' ' + vend.data[0].apellido1 + ' ' + vend.data[0].apellido2,
                                            nombreproyecto: 'Proyecto número ' + proyData.pkIdProyecto,
                                            linkproyecto: `${Constants.app}/proyectos/${proyData.pkIdProyecto}`,
                                            imgprimera: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                                        }
                                    }
                                }
                                axios.post(Constants.api+'/v1/send_mail/', email)
                                .then(res => {console.log('email sent')});
                            });
                //fin
                proyData.notas = textArea.value;
                proyData.fkIdDisenador = Number(disenadorSelected);
                proyData.currentStep = 7;
                console.log(proyData);
                axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
                    proyData)
                .then(async (resp)=> {
                    let proyData = JSON.parse(JSON.stringify(props.miProyecto));
                    proyData.notas = textArea.value;
                    proyData.fkIdDisenador = Number(disenadorSelected);
                    proyData.currentStep = 7;
                    props.setProyecto(proyData);
                    setWheel(false);
                    props.nextStep();
                });
                // genera alerta
                axios.post(Constants.api+'/v1/alerta', 
                    {
                        fkIdUsuario: proyData.fkIdDisenador,
                        mensaje: 'Solicitud de diseño para '+ props.miProyecto.comprador[0].nombre,
                        revisada: 0,
                        link: '/proyectos/'+props.miProyecto.pkIdProyecto
                    })
                .then(async (resp)=> {
                    console.log('resp, alertas', resp);
                });
            });
        } else {
            alert('Por favor seleccione un diseñador');
        }
    }

    const changeDisenador = (u) => {
        setDisenadorSelected(u);
    }

    const handleChangeCheck = (e,ad) => {
        // ad.send = e.target.checked;
        console.log(ad);
        if(e.target.checked){
            docsMarked.push(ad);
        } else {
            docsMarked = docsMarked.filter(d => d != ad);
        }
        console.log(docsMarked);
    }

    return (
        <div>
            <div className="form-container">
                <div className="cont">
                    <h3>Notas para el diseñador:</h3>
                    <p>Notas del proyecto</p>
                    <textarea className="notas"></textarea>
                    <div>
                        Documentos de vendedor
                        <UsuarioArchivos type="" seleccionarArchivo={(e) => seleccionarArchivo(e, 'vendedor')}> </UsuarioArchivos>
                        {newDocsVendedor.length > 0 && <div>
                            Archivos a agregar(los archivos marcados seran enviados al diseñador por correo):
                            <ul>
                                {newDocsVendedor.map(ad => {
                                    return <li><input type="checkbox" onChange={e => handleChangeCheck(e, ad.name)}/><span key={ad.name}>{ad.name}</span><span onClick={(e) => {borrarArchivoLocal(ad)}} className="delete-file">Borrar Archivo</span></li>
                                })}
                            </ul>
                        </div>}
                        {docsVendedor.length > 0 && <div>
                            Documentos de vendedor asignados al proyecto(los archivos marcados seran enviados al diseñador por correo):
                            <ul>
                                {docsVendedor.map(ad => {
                                    return (
                                        <li>
                                            <a href={Constants.imgHost + ad.Key} key={ad.Key} target="_blank"><input type="checkbox" onChange={e => handleChangeCheck(e, ad.Key.split('/').pop())}/>{ad.Key.split('/').pop()}</a> <span onClick={(e) => {borrarArchivo(ad)}} className="delete-file">Borrar Archivo</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>}
                    </div>
                    <div className="form-group">
                        <span>Diseñadores</span>
                        <select value={disenadorSelected} name="disenadorSelected" onChange={(e) => changeDisenador(e.target.value)}>
                            <option key="0" value="">Seleccione el Disenador</option>
                            {disenadores.map((d) => <option key={d.fkIdUsuario} value={d.fkIdUsuario}>{d.nombre} {d.apellido1}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className="form-container">
                <div>
                    <button className="btn btn-cancel" onClick={props.prevStep}>
                        Atrás
                    </button>
                    <button type="submit" className="btn btn-save" onClick={enviarDisenador}>
                        Enviar a diseñador
                    </button>
                </div>
            </div>
            {openWheel && <Wheel/>}
        </div>
    );
}

export default Step6;