import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom';
import { Formik, ErrorMessage} from 'formik';
import { PDFViewer, BlobProvider, Image, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import axios from 'axios';
import Constants from '../../../Constants';
import MainContext from '../../../MainContext';
import './Step5.css';
import header from '../../../assets/img/header.png';
import social from '../../../assets/img/social.png';
import footer from '../../../assets/img/footer.png';
import Wheel from '../../../shared/dialog/wheel/Wheel';
import * as jsPDF from 'jspdf';
import * as QueryString from "query-string"
import autoTable from 'jspdf-autotable';
import Calc from './calc';

function Step5(props) {
    const [porcentaje, setPorcentaje] = useState(0);
    const [alerta, setAlerta] = useState();
    const [openWheel, setWheel] = useState(false);
    const [acabados, setAcabados] = useState([]);
    const [distribuciones, setDistribuciones] = useState([]);
    let [estimaciones, setEstimaciones] = useState([]);// multi
    const calc = new Calc();
    const mainContext = React.useContext(MainContext);
    let sendBlob = false;
    let myBlob = null;
    const [proyData, setProyData] = useState({}); 
    const [promociones, setPromociones] = useState([]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    useEffect(() => {
        setWheel(true);
        if (props.miProyecto.vendedor && props.miProyecto.vendedor.length >= 1) {
            setProyData(props.miProyecto);
        } else {
            axios.get(Constants.api + '/v1/proyecto/'+props.miProyecto.pkIdProyecto)    	
            .then((resp)=>{
                setProyData(resp.data[0]);
            });
        }
        axios.get(Constants.api+'/v1/promociones')
        .then((respDP)=>{
            let inicio = new Date(respDP.data[1].fechaInicio.split('T')[0]);
            let fin = new Date(respDP.data[1].fechaFin.split('T')[0]);
            let hoy = new Date();
            setPromociones(respDP.data.filter(promo => new Date(promo.fechaInicio.split('T')[0]) < hoy && new Date(promo.fechaFin.split('T')[0]) > hoy));
        });
        axios.get(Constants.api+'/v1/distribucion')
	    .then((resp)=>{	
            resp.data = resp.data.map(dist => {
                dist.fkIdDistribucion = dist.pkIdDistribucion;
                dist.nivel = 0; 
                dist.largo = ''; 
                dist.ancho = ''; 
                return dist;
            });

            axios.get(Constants.api+'/v1/proyecto_distribucion/'+props.miProyecto.pkIdProyecto)
	        .then((respDP)=>{
                resp.data.forEach(element => {
                    if (respDP.data.some((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)) {
                        element.ancho = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].ancho;
                        element.largo = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].largo;
                        element.nivel = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].nivel;
                        element.pkIdProyectoDistribucion = respDP.data.filter((dist) => dist.fkIdDistribucion == element.fkIdDistribucion && dist.fkIdProyecto == props.miProyecto.pkIdProyecto)[0].pkIdProyectoDistribucion;
                    }
                    element.fkIdProyecto = props.miProyecto.pkIdProyecto;
                });
                setPorcentaje(resp.data.filter(dist => dist.nombre.trim() === 'Pasillos')[0].largo);
                resp.data = resp.data.filter(dist => dist.largo>=1 && dist.nombre.trim() !== 'Pasillos');
                setDistribuciones(resp.data);
                let arrReq = [];
                let arrAcabados = [];
                arrReq.push(axios.get(Constants.api+'/v1/acabado'));
                arrReq.push(axios.get(Constants.api+'/v1/version_acabado_proyecto?proyecto='+props.miProyecto.pkIdProyecto));
                Promise.all(arrReq).then(resp =>{
                    const verAcabProy = resp[1].data;
                    const acabados = resp[0].data.sort((a,b) => (a.rankingCategoria > b.rankingCategoria) ? 1 : ((b.rankingCategoria > a.rankingCategoria) ? -1 : 0))
                    const acabadosMap = acabados.map(ac => {
                        ac.selected = ac.versiones.some(version => verAcabProy.some(verAc => verAc.fkIdVersionAcabado === version.pkIdVersionAcabado && verAc.fkIdProyecto == props.miProyecto.pkIdProyecto));
                        return ac;
                    });
                    arrAcabados = acabadosMap.filter(acab => acab.selected);
                    setAcabados(arrAcabados);
                    //console.log('acabados:',arrAcabados);
                    loadFiles();
                    setWheel(false);
                }).catch(error => {
                    if (error.message.indexOf('401') >= 0) {
                        //this.context.logOut();
                    }
                });
            });
            const params = QueryString.parse(props.location.search);
            if(params.alerta){
                setAlerta(params.alerta);
            }
	    });
    }, []);

    const enviarACliente = async () => {
        setWheel(true);
        // genera alerta
        axios.post(Constants.api+'/v1/alerta', 
            {
                fkIdPermiso: 13,
                mensaje:'Hay una nueva estimación para revisión',
                revisada: 0,
                link: '/proyectos/'+props.miProyecto.pkIdProyecto
            })
        .then(async (resp)=> {
            //console.log('resp, alertas', resp);
        });
        let respUser = await axios.get(Constants.api + '/v1/usuario/'+props.miProyecto.fkIdUsuario);
        axios.get(Constants.api + '/v1/usuario/'+props.miProyecto.fkIdVendedor)
        .then(vend => {
            let email = {
                emailFiles: [{
                    type: 'S3',
                    path: estimaciones[estimaciones.length - 1].Key
                }],
                emailBody:{
                    to: [respUser.data[0].correos[0].correo],
                    from: vend.data[0].correos[0].correo,
                    subject: 'Estimación Constructora Izquierdo',
                    body: 'Estimación Constructora Izquierdo',
                    type: 'template',
                    name: 'estimacion',
                    mappings: {
                        nombrecliente: props.miProyecto.comprador[0].nombre + ' ' + props.miProyecto.comprador[0].apellido1 + ' ' + props.miProyecto.comprador[0].apellido2,
                        imgprimera: `<img src="${Constants.imgHost}assets/email/estimacion.jpeg" alt="estimacion" width="400"/>`,
                        imgsegunda: `<img src="${Constants.imgHost}firmas/user/${proyData.fkIdVendedor}/firma" alt="firma" width="433"/>`
                    }
                }
            }
            axios.post(Constants.api+'/v1/send_mail/', email)
            .then(res => {});
        })
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        proyData.ultimaEstimacionEnviada = 1;
        axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, proyData)
        .then(async (resp)=>{
            axios.get(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto)
            .then(body => {
                //console.log(body.data);
                props.setProyecto(body.data[0]);
                setWheel(false);
            })
        });
    }
    const getDataUri = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            console.log('load img '+url+' '+ new Date())
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
        xhr.responseType = 'blob';
        xhr.send();
    }

    const numberWithCommas = (x) => {
        x = x? parseInt(x.toString().replace(/,/g, '')): '';
        return x? x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):'';
    }
    const guardaArchivos = async () => {
        setWheel(true);
        //const num = estimaciones.length + 1;
        const num = estimaciones.length ? parseInt(estimaciones[estimaciones.length -1].Key.split('.pdf')[0].split('.')[1]) + 1 : 1;
        console.log('num PDF:', num)
        console.log('comienza a generar el pdf: ' + new Date());
        let arrBody = [];
        let totalM2 = 0;
        let totalPasillos = 0;
        let nivel1 = 0;
        let nivel2 = 0;
        distribuciones.forEach(dist => {
            const distArray = [dist.nombre, dist.nivel, dist.largo, dist.ancho, Math.round((dist.largo * dist.ancho) * 100) / 100 + 'm2'];
            totalM2 += (dist.largo * dist.ancho);
            if (dist.nivel === 1) {
                nivel1 += (dist.largo * dist.ancho);
            } else {
                nivel2 += (dist.largo * dist.ancho);
            }
            if((dist.largo * dist.ancho)>0) {
                arrBody.push(distArray);
            }
        });
        const mtsPasillos = totalM2*(porcentaje/100);
        let precioTotal = await calc.getPrecioTotal(acabados, distribuciones, props.miProyecto, mtsPasillos);
        //const precioTotal = 51179567.70;
        totalPasillos = totalM2 + mtsPasillos;
        arrBody.push(['TOTAL', '', '', '', Math.round(totalM2 * 100) / 100 + 'm2']);
        arrBody.push(['PASILLOS', '', parseInt(porcentaje)+'%', '', Math.round(mtsPasillos * 100) / 100]);
        arrBody.push(['TOTAL (M2)', '', '', '', Math.round(totalPasillos * 100) / 100 + 'm2']);
        arrBody.push(['SERVICIOS', '', '', '', '']);
        if (promociones.length) {
            arrBody.push(['SIN DESCUENTO', '', '', '', 'CRC '+ numberWithCommas(precioTotal)]);
            promociones.forEach((promo) => {
                //console.log(promo.promocion/100,precioTotal*(promo.promocion/100))
                precioTotal = precioTotal-precioTotal*(promo.promocion/100)
                arrBody.push(['APLICA DESCUENTO:', '', '', '', promo.descripcion]);
            });
        }
        arrBody.push(['CONSTRUCCIÓN TOTAL', '', '', '', 'CRC '+ numberWithCommas(precioTotal)]);
        arrBody.push(['PLANOS CONSTRUCTIVOS', '', '', '', 'CRC '+ numberWithCommas(precioTotal*0.04)]);
        arrBody.push(['IVA', '', '', '', 'CRC '+ numberWithCommas((precioTotal*0.125)*0.13 + precioTotal*0.04*0.13)]);
        //arrBody.push(['IVA', '', '', '', 'CRC '+ numberWithCommas((precioTotal+precioTotal*0.045)*0.08)]);
        arrBody.push(['PERMISOS CONSTRUCCIÓN', '', '', '', '']);
        arrBody.push(['CFIA', '', '', '', 'CRC '+ numberWithCommas(precioTotal*0.003)]);
        arrBody.push(['INS ', '', '', '', 'CRC '+ numberWithCommas((precioTotal*0.3)*0.036)]);
        arrBody.push(['MUNICIPIO', '', '', '', 'CRC '+ numberWithCommas(precioTotal*0.01)]);
        arrBody.push(['', '', '', '', 'CRC '+ numberWithCommas(Math.round(((precioTotal*0.003)+((precioTotal*0.3)*0.036)+(precioTotal*0.01)) * 100) / 100)]);
        let headerBase64 = '';
        let footerBase64 = '';
        getDataUri(Constants.imgHost + 'logos/headerCFIA.png', async function(dataUrl) {
            headerBase64 = dataUrl;
            getDataUri(Constants.imgHost + 'pdf/footer-social.jpeg', async function(dataUrl2) {
                footerBase64 = dataUrl2;
                
                let doc = new jsPDF();
                console.log('test tipo:',props.miProyecto.inflacionUbicacion,typeof(props.miProyecto.inflacionUbicacion));

                let zonaG;
                switch (props.miProyecto.inflacionUbicacion) {
                    case '0.00':
                        zonaG = 'GAM';
                        break;
                    case '5.00':
                        zonaG = 'ZONA ALTA MONTAÑA/CARTAGO';
                        break;
                    case '12.00':
                        zonaG = 'ZONA NORTE';
                        break;
                    case '20.00':
                        zonaG = 'GUANACASTE';
                        break;
                    case '12.01':
                        zonaG = 'PUNTARENAS';
                        break;
                    case '20.01':
                        zonaG = 'ZONA SUR';
                        break;
                    case '20.02':
                        zonaG = 'LIMON';    
                        break;  
                }
                
                doc.addImage(headerBase64, 'png', 10, 0, 200, 40);

                doc.setFontSize(8);
                doc.setFontType('bold');
                doc.text(20, 70, `ASESOR: ${proyData.vendedor[0].nombre} ${proyData.vendedor[0].apellido1} ${proyData.vendedor[0].apellido2}`);
                doc.text(20, 75, `VENDEDOR: ${proyData.vendedor[0].nombre} ${proyData.vendedor[0].apellido1} ${proyData.vendedor[0].apellido2}`);
                doc.text(20, 80, `CONSECUTIVO: E-${proyData.pkIdProyecto}.${num}`);
                doc.text(20, 85, `ZONA: ${zonaG}`);
                doc.text(20, 95, `Nombre: ${props.userData.nombre}`);

                doc.text(20, 100, `Correo electronico: ${props.userData.correos[0].correo}`);
                doc.text(20, 105, `Telefono: ${props.userData.telefonos[0].telefono}`);
                doc.text(20, 110, `FECHA: ${(new Date().toJSON().slice(0,10).split('-').reverse().join('/')).toString()}`);
                doc.text(20, 115, `VIGENCIA ESTIMACION: 15 dias`);
                
                doc.setFontSize(8);
                doc.setTextColor(255, 0, 0);
                doc.text(20, 125, 'Acabados:');
                doc.setFontSize(6);
                doc.setTextColor(0, 0, 0);
                doc.setFontType('normal');
                let posY = 130;
                acabados.forEach((acabado) => {
                    if(posY > 280 && doc.internal.getCurrentPageInfo().pageNumber === 1) {
                        doc.addPage();
                        posY = 10;
                    }
                    if(acabado.nombre !== 'No Aplica') {
                        const reportTitle = `- ${acabado.categoria}: ${acabado.nombre}`;
                        if (reportTitle.length>60) {
                            const splitTitle = doc.splitTextToSize(reportTitle, 65);
                            doc.text(20, posY, splitTitle);
                            posY = posY + 5;
                        } else{
                            doc.text(20, posY, reportTitle);
                            posY = posY + 3;
                        }
                    }
                })

                doc.setFontSize(20);
                doc.setPage(1);
                doc.text(110, 45, 'Estimación Económica');
                doc.setFontSize(4);
                doc.autoTable({
                    head: [['Nombre', 'Nivel', 'Largo', 'Ancho', 'Área M2']],
                    body: arrBody,
                    headerStyles: {
                        fillColor: [191, 191, 191],
                        textColor: [0, 0, 0],
                        halign: 'center'
                    },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        if (data.row.index === arrBody.length -(10+promociones.length+1)) {
                            doc.setFillColor(191, 191, 191);
                            doc.setFontType("bold");
                            doc.setFontSize(7);
                        };
                        if ((data.row.index === arrBody.length - 12 || data.row.index >= arrBody.length -10)) {
                            doc.setFontType("bold");
                            doc.setFontSize(7);
                        }
                        if (data.row.index === arrBody.length -(9+promociones.length+1) || data.row.index === arrBody.length - (5+promociones.length+1)) {
                            doc.setFillColor(77, 77, 77);
                            doc.setTextColor(255, 255, 255);
                            doc.setFontSize(7);
                        };
                    },
                    bodyStyles: {
                        halign: 'center'
                    },
                    startX: 200,
                    styles: {width: '50%', fontSize: 7},
                    margin: {top: 50, left: 89}
                });
                doc.setFontSize(7);
                doc.setFontType('bold');
                if(doc.getNumberOfPages() > 1){
                    doc.setPage(doc.getNumberOfPages())
                }else if(posY > 200 && doc.internal.getCurrentPageInfo().pageNumber === 1) {
                    doc.addPage();
                    posY = posY + 10;
                }
                posY = 200;
                doc.text(20, posY +5, 'Notas importantes:');
                doc.setFontType('normal');
                doc.text(20, posY+10, '- Escaleras flotantes deben tener pared de apoyo lateral.');
                doc.text(20, posY+15, '- Sistema de TS Séptico considerado convencional según');
                doc.text(20, posY+20, 'Código Instalaciones Hidráulicas.');
                doc.text(20, posY+25, '- Diseño estructural con base a Código Sísmico de CR,');
                doc.text(20, posY+30, 'voladizos considerados menores a 4 m Apollo Doble ');
                doc.text(20, posY+35, 'y 1,5m Apoyo simple.');
                doc.text(20, posY+40, '- Se Asume Instalación Eléctrica simple 5 salidas eléctricas');
                doc.text(20, posY+45, 'por habitación  3 tomas 1 salida iluminación 1 apagador.');
                doc.text(20, posY+50, '- Pintura 1 Color Básico +2 Acentos.');
                doc.text(20, posY+55, '- Cambiar alguna de estas condiciones por diseño o solicitud ');
                doc.text(20, posY+60, 'del cliente podría tener un impacto económico.');

                doc.addImage(footerBase64, 'png', 5, posY+70, 200, 25);

                const blob = doc.output('blob');
                if (blob) {
                const fileData = await toBase64(blob);

                console.log('comienza a subir archivo' + new Date());
                const res = await axios.post(Constants.api+'/v1/file-upload',
                    {
                        fileName: `E-${proyData.pkIdProyecto}.${num}.pdf`,
                        filePath: `files/project/${proyData.pkIdProyecto}/estimacion/`,
                        contentType: blob.type,
                        file: fileData.replace(/^data:.+;base64,/, '')
                    }).then(succ => {
                        console.log('proceso terminado: ' + new Date());
                        if(proyData.estimacionAprobadaCliente == 1 || proyData.ultimaEstimacionEnviada == 1){
                            let proyObj = JSON.parse(JSON.stringify(proyData));
                            delete proyObj['comprador'];
                            delete proyObj['vendedor'];
                            delete proyObj['estimador'];
                            proyObj.estimacionAprobadaCliente = 0;
                            proyObj.ultimaEstimacionEnviada = 0;
                            axios.put(Constants.api+'/v1/proyecto/'+proyObj.pkIdProyecto, 
                            proyObj)
                            .then(async (resp)=> {
                                let proyObj = JSON.parse(JSON.stringify(props.miProyecto));
                                proyObj.estimacionAprobadaCliente = 0;
                                proyObj.ultimaEstimacionEnviada = 0;
                                props.setProyecto(proyObj);

                                setWheel(false);
                            });
                        }
                        //genera alerta
                        axios.post(Constants.api+'/v1/alerta', 
                            {
                                fkIdRol: 1,
                                mensaje:'Hay una nueva estimación que requiere revisión',
                                revisada: 0,
                                link: '/proyectos/'+props.miProyecto.pkIdProyecto
                            })
                        .then(async (resp)=> {
                            //console.log('resp, alertas', resp);
                        });
                        loadFiles();
                    })
                }
            });
        });
    }

    const borrarArchivo = file => {
        axios.delete(Constants.api+'/v1/file-upload?path='+file.Key)
        .then(succ => {
            loadFiles();
        })
    }

    const loadFiles = () => {
        setWheel(true);
		axios.get(Constants.api+`/v1/file-upload?path=files/project/${props.miProyecto.pkIdProyecto}/estimacion/`)
        .then(res => {
            setEstimaciones(res.data.Contents);
            if (!res.data.Contents.length) {
               // guardaArchivos(myBlob, 1);
            }
            setWheel(false);
        });
    }

    const aprobarCotizacion = () => {
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        proyData.estimacionAprobadaCliente = 1;
        axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
            proyData)
        .then(async (resp)=> {
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            proyData.estimacionAprobadaCliente = 1;
            props.setProyecto(proyData);
        });
    }

    const verificarEstimacion = () => {
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        if(alerta){
            axios.get(Constants.api + '/v1/alerta/'+alerta)
            .then(res => {
                let a = res.data[0];
                a.revisada = 1;
                delete a['date'];
                delete a['rev'];
                delete a['tableData'];
                axios.put(Constants.api + '/v1/alerta/'+alerta, a)
                .then(res => {
                    
                })
            });
        }
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        proyData.fkIdEstimacionAprobadaGerencia = JSON.parse(localStorage.getItem('izqToken')).usuarioId;
        axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
            proyData)
        .then(async (resp)=> {
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            proyData.estimacionAprobadaCliente = JSON.parse(localStorage.getItem('izqToken')).usuarioId;
            props.setProyecto(proyData);
        });
    }

    const solicitarCambios = () => {
        let proyData = JSON.parse(JSON.stringify(props.miProyecto));
        if(alerta){
            axios.get(Constants.api + '/v1/alerta/'+alerta)
            .then(res => {
                let a = res.data[0];
                a.revisada = 1;
                delete a['date'];
                delete a['rev'];
                delete a['tableData'];
                axios.put(Constants.api + '/v1/alerta/'+alerta, a)
                .then(res => {
                    
                })
            })
        }
        axios.post(Constants.api+'/v1/alerta', 
            {
                fkIdUsuario: proyData.fkIdVendedor,
                mensaje:'Un gerente realizó una solicitud de cambios',
                revisada: 0,
                link: '/proyectos/'+props.miProyecto.pkIdProyecto
            })
        .then(async (resp)=> {
            //console.log('resp, alertas', resp);
        });
        delete proyData['comprador'];
        delete proyData['vendedor'];
        delete proyData['estimador'];
        proyData.notasDeGerencia = document.getElementById('notasgerencia').value;
        axios.put(Constants.api+'/v1/proyecto/'+proyData.pkIdProyecto, 
            proyData)
        .then(async (resp)=> {
            let proyData = JSON.parse(JSON.stringify(props.miProyecto));
            proyData.notasDeGerencia = document.getElementById('notasgerencia').value;
            props.setProyecto(proyData);
        });
    }



    const onSubmit = () => {
        setWheel(true);
        const bodyP = JSON.parse(JSON.stringify(props.miProyecto));
        delete bodyP['comprador'];
        delete bodyP['vendedor'];
        delete bodyP['estimador'];
        bodyP.currentStep = 6;
        
        axios.put(Constants.api+'/v1/proyecto/'+props.miProyecto.pkIdProyecto, 
        bodyP)
        .then((resp)=>{
            setWheel(false);
            props.nextStep();
            props.setProyecto(bodyP);
        });
    }

    const getDateFormat = (date) => {
        date = date.split('T')[0]; 
        return date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0];
    } 

    return (
        <div className="form-container">
            <div>
                <div>
                    <ul>
                        {estimaciones.map(est => {
                            return (
                                <li>
                                    <a href={Constants.imgHost + est.Key} key={est.Key} target="_blank">{est.Key.split('/').pop()}</a> <span> Creado: {getDateFormat(est.LastModified)} </span> {props.miProyecto.ultimaEstimacionEnviada == 0 && <span onClick={(e) => {borrarArchivo(est)}} className="delete-file"> Borrar Archivo</span>}
                                </li>
                            )
                        })}
                    </ul>
                    <p><span onClick={guardaArchivos} className="create-file">+ Generar nueva estimación</span></p>
                </div>
                {props.miProyecto.estimador && props.miProyecto.estimador.length > 0 && <div>
                    La última estimación fue enviada al cliente por: {props.miProyecto.estimador[0].nombre + ' ' + props.miProyecto.estimador[0].apellido1}
                </div>}
                {props.miProyecto.ultimaEstimacionEnviada == 1 && props.miProyecto.estimacionAprobadaCliente == 0 && <div>
                    La última estimación no ha sido aprobada por el cliente aún.
                    {/* <input type="button" value="Brindar aprobacion de cliente" onClick={aprobarCotizacion}></input> */}
                </div>}
                <div className="form-group buttons">
                    <button className="btn btn-cancel" onClick={props.prevStep}>
                        Atrás
                    </button>
                    {estimaciones.length > 0 && <input type="button" onClick={enviarACliente} className="btn btn-save" value="Enviar a cliente"/>}
                    {props.miProyecto.ultimaEstimacionEnviada == 1 && <button type="button" className="btn btn-save" onClick={onSubmit}>
                        Aprobar y Pasar a diseño
                    </button>}
                </div>
            </div>
			{openWheel && <Wheel/>}
        </div>
    );
}

export default withRouter(Step5);